var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('t-modal',{attrs:{"header":"","value":_vm.show,"variant":"story"},on:{"closed":_vm.closeModal}},[_c('t-card',{attrs:{"variant":_vm.theme === 'dark' ? 'dark-story' : 'light-story'}},[_c('div',{staticClass:"story-container"},[_c('div',{staticClass:"story-content"},[_c('div',{staticClass:"relative h-full"},[_c('div',{staticClass:"absolute top-4 w-full px-3",staticStyle:{"z-index":"3"}},[_c('div',{staticClass:"grid",style:(("grid-template-columns: repeat(" + (_vm.stories.length) + ", minmax(0, 1fr));"))},_vm._l((_vm.stories),function(story,index){return _c('div',{key:story.id,staticClass:"bg-gray-400 h-1",staticStyle:{"margin":"0 1.5px","filter":"drop-shadow(0 0 0.7px #000)"}},[(_vm.currentStoryIndex === index)?_c('div',{staticClass:"bg-gray-100 h-1",style:(("width: " + (_vm.percent()) + "%; \n                   transition-timing-function: ease-out;\n                    -webkit-transition: " + (_vm.percent() === 0 ? '' : 'width 0.5s') + ";\n                    -moz-transition: " + (_vm.percent() === 0 ? '' : 'width 0.5s') + ";\n                    -ms-transition: " + (_vm.percent() === 0 ? '' : 'width 0.5s') + ";\n                    -o-transition: " + (_vm.percent() === 0 ? '' : 'width 0.5s') + ";\n                    transition: " + (_vm.percent() === 0 ? '' : 'width 0.5s') + ";\n                  \n                  "))}):_c('div',{staticClass:"bg-gray-100 h-1",style:(story.viewed === 1 && index < _vm.currentStoryIndex
                      ? 'width: 100%'
                      : 'width: 0%')})])}),0)]),_c('div',{staticClass:"w-full px-6 absolute top-8",staticStyle:{"filter":"drop-shadow(0 0 0.7px #000)","z-index":"3"}},[_c('div',{staticClass:"w-full flex justify-between"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-16 h-16"},[_c('profile-picture',{attrs:{"data":_vm.storiesUser,"sizePx":128}})],1),_c('div',{staticClass:"ml-3 my-auto"},[_c('p',{staticClass:"text-black dark:text-white text-sm sm:text-md font-bold",staticStyle:{"display":"flex","align-items":"center","cursor":"pointer"},on:{"click":_vm.openProfile}},[_vm._v(" "+_vm._s(_vm.storiesUser.username)+" "),(_vm.storiesUser.verified == 1)?_c('span',{staticClass:"ml-1"},[_c('img',{staticStyle:{"{ display":"flex","height":"16px","width":"16px"},attrs:{"src":"/static/user_verified.png"}})]):_vm._e()]),(_vm.currentStory && _vm.currentStory.created_at)?_c('p',{staticClass:"text-black dark:text-white text-sm sm:text-md"},[_vm._v(" "+_vm._s(_vm._f("fromNow")(_vm.currentStory.created_at))+" ")]):_vm._e()])]),(_vm.storiesUser.id === _vm.loggedUser.id)?_c('t-dropdown',{scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
                    var mousedownHandler = ref.mousedownHandler;
                    var focusHandler = ref.focusHandler;
                    var blurHandler = ref.blurHandler;
                    var keydownHandler = ref.keydownHandler;
return _c('div',{staticClass:"flex"},[_c('button',{staticClass:"flex items-center justify-center flex-shrink-0 px-3 py-2 text-black dark:text-white bg-transparent rounded-r",on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_c('icon',{staticClass:"mr-2 my-auto text-black dark:text-white text-xl sm:text-2xl",staticStyle:{"cursor":"pointer"},attrs:{"icon":"ellipsis-v"}})],1)])}},{key:"default",fn:function(ref){
                    var blurHandler = ref.blurHandler;
return _c('div',{},[_c('button',{staticClass:"block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800",attrs:{"role":"menuitem"},on:{"blur":blurHandler,"click":_vm.deleteStory}},[_c('img',{staticClass:"mr-1 inline-block",staticStyle:{"width":"20px","height":"20px","filter":"invert(22%) sepia(80%) saturate(1684%)"},attrs:{"src":"/static/icons/delete.svg","alt":"Delete icon"}}),_vm._v(" "+_vm._s(_vm.$i18n.t("delete"))+" ")])])}}],null,false,3061385593)}):_vm._e()],1)]),(_vm.hasPrevStory)?_c('span',{staticClass:"story-nav left",staticStyle:{"z-index":"3"},on:{"click":function($event){return _vm.changeStory('backwards', true)}}},[_c('icon',{attrs:{"icon":"angle-left"}})],1):_vm._e(),(_vm.hasNextStory && _vm.allowNextStory)?_c('span',{staticClass:"story-nav right",staticStyle:{"z-index":"3"},on:{"click":function($event){return _vm.changeStory('forward', true)}}},[_c('icon',{attrs:{"icon":"angle-right"}})],1):_vm._e(),_c('div',{staticClass:"flex items-center justify-center h-full"},[(_vm.currentStory.type === 1)?_c('img',{ref:"story-loader",staticClass:"w-1/3 mx-auto object-cover",attrs:{"src":_vm.theme === 'dark'
                  ? '/static/loading_darkmode.gif'
                  : '/static/loading_lightmode.gif',"alt":"story-loader"}}):_vm._e(),(_vm.currentStory.type === 1)?_c('img',{ref:'story-' + _vm.currentStory.id,staticClass:"hidden mx-auto object-cover h-full w-full select-none",attrs:{"alt":_vm.currentStory.text,"src":_vm.picture(_vm.currentStory)},on:{"loadstart":_vm.showImageLoader,"error":_vm.showImageLoader,"load":_vm.onImageLoad,"mousedown":function($event){return _vm.toggleTimer('pause')},"mouseup":function($event){return _vm.toggleTimer('resume')}}}):_vm._e()]),(_vm.currentStory.type === 2)?_c('video',{staticClass:"mx-auto w-full",class:_vm.currentStory.aspect_ratio < 1 ? 'h-full object-cover' : '',staticStyle:{"position":"absolute","left":"50%","top":"50%","-webkit-transform":"translateX(-50%) translateY(-50%)","transform":"translateX(-50%) translateY(-50%)"},attrs:{"id":"story-video","src":_vm.currentStory.imgkit_url_story || _vm.currentStory.url_story,"playsinline":"","autoplay":""},on:{"timeupdate":_vm.updateProgress,"pause":_vm.onVideoPause,"play":_vm.onVideoPlay,"mousedown":function($event){return _vm.toggleTimer('pause')},"mouseup":function($event){return _vm.toggleTimer('resume')}}}):_vm._e()])]),_c('div',{staticClass:"story-footer",staticStyle:{"z-index":"3"}},[_c('div',{staticClass:"w-5/6 px-1 py-1 my-auto max-h-full overflow-y-auto"},[_c('p',{staticClass:"text-black dark:text-white text-xs lg:text-sm select-none",domProps:{"innerHTML":_vm._s(_vm.$detectHashtagsAndUsernames(_vm.currentStory.text))},on:{"click":_vm.handleProcessedTextClick}})]),_c('div',{staticClass:"w-1/6 mx-auto my-auto"},[(_vm.storiesUser.id === _vm.loggedUser.id)?_c('div',{staticClass:"flex flex-col justify-center items-center"},[_c('div',{staticClass:"flex justify-center"},[_c('img',{staticClass:"mr-1 mx-auto my-auto select-none",class:_vm.currentStory.liked === 0 ? 'dark:filter invert' : '',staticStyle:{"cursor":"pointer"},attrs:{"src":_vm.currentStory.liked === 0
                    ? '/static/icons/jevi.svg'
                    : '/static/icons/jeviFilled.svg',"alt":"Jevi button","width":"24px"},on:{"click":_vm.like}}),_c('p',{staticClass:"my-auto text-black dark:text-white text-xs"},[_vm._v(" "+_vm._s(_vm.calculateHighNumber(_vm.currentStory.total_likes))+" ")])]),_c('div',{staticClass:"flex justify-center"},[_c('img',{staticClass:"mr-1 my-auto dark:filter invert",attrs:{"src":"/static/icons/views.svg","alt":"Views icon","width":"20px"}}),_c('p',{staticClass:"my-auto text-black dark:text-white text-xs"},[_vm._v(" "+_vm._s(_vm.calculateHighNumber(_vm.currentStory.total_views))+" ")])])]):_c('div',{staticClass:"flex flex-row justify-center items-center"},[_c('div',{staticClass:"flex justify-center"},[_c('img',{staticClass:"mr-1 mx-auto my-auto select-none",class:_vm.currentStory.liked === 0 ? 'dark:filter invert' : '',staticStyle:{"cursor":"pointer"},attrs:{"src":_vm.currentStory.liked === 0
                    ? '/static/icons/jevi.svg'
                    : '/static/icons/jeviFilled.svg',"alt":"Jevi button","width":"24px"},on:{"click":_vm.like}})]),_c('div',{staticClass:"flex justify-center"},[_c('img',{staticClass:"mr-1 mx-auto my-auto select-none dark:filter invert",staticStyle:{"cursor":"pointer"},attrs:{"src":"/static/icons/comment.svg","alt":"Comments button","width":"24px"},on:{"click":function($event){_vm.toggleTimer('pause')
                    _vm.commentModal = true}}})])])])])])])],1),_c('t-modal',{attrs:{"header":_vm.$i18n.t('send_comment'),"value":_vm.commentModal,"clickToClose":false,"escToClose":false,"variant":"lg"},on:{"closed":_vm.closeCommentModal}},[_c('t-input-group',{staticClass:"my-5 relative",attrs:{"feedback":_vm.errors.first('commentStoryMsg') ? _vm.errors.first('commentStoryMsg') : '',"variant":_vm.errors.first('commentStoryMsg') ? 'danger' : ''}},[_c('t-textarea',{directives:[{name:"validate",rawName:"v-validate",value:('simple_required|min:1|max:1000'),expression:"'simple_required|min:1|max:1000'"}],attrs:{"data-vv-validate-on":"input","name":"commentStoryMessage","placeholder":_vm.$i18n.t('add_comment'),"id":"comment-textarea"},model:{value:(_vm.commentMsg),callback:function ($$v) {_vm.commentMsg=$$v},expression:"commentMsg"}})],1),_c('div',{staticClass:"flex justify-center"},[_c('t-button',{attrs:{"type":"button","disabled":_vm.commentLoading},on:{"click":function($event){return _vm.comment()}}},[(_vm.commentLoading)?_c('span',[_c('t-loader',{attrs:{"extraClass":"border-orange-primary"}})],1):_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(_vm.$i18n.t("send")))])])],1)],1),_c('t-modal',{attrs:{"header":'',"value":_vm.storyDeletionConfirmation,"variant":"md"},on:{"closed":_vm.storyDeletionModalClosed},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"flex justify-end"},[_c('t-button',{staticClass:"mr-2",attrs:{"type":"button","variant":"gray"},on:{"click":function($event){_vm.storyDeletionConfirmation = false}}},[_vm._v(" "+_vm._s(_vm.$i18n.t("no"))+" ")]),_c('t-button',{attrs:{"type":"button"},on:{"click":_vm.deleteStory}},[_vm._v(_vm._s(_vm.$i18n.t("delete")))])],1)]},proxy:true}])},[_c('div',{staticClass:"p-3"},[_c('p',{staticClass:"mb-1 text-black dark:text-white"},[_vm._v(" "+_vm._s(_vm.$i18n.t("story_delete_confirmation"))+" ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }