<template>
  <div>
    <div class="vertical-nav-menu bg-white dark:bg-jevi-dark vertical-scroll z-40"
      :class="menuExpanded ? 'menu-show' : ''">
      <router-link to="/home">
        <img class="px-5 pt-5" :src="
          theme === 'dark'
            ? '/static/logo_darkmode.png'
            : '/static/logo_lightmode.png'
        " />
      </router-link>

      <div class="menu-content vertical-scroll">
        <item v-for="item in menu" :key="'menu-item-' + item.id" :name="item.name" :url="item.url" :icon="item.icon"
          :submenu="item.submenu" :isLink="
            item.icon.includes('new_post') || item.icon.includes('logout')
              ? false
              : true
          " @click.native="functionMap(item.icon)" :open="isGroupActive(item)"></item>
      </div>
    </div>
    <div v-if="menuExpanded" class="lg:hidden bg-gray-700 opacity-50 h-screen w-full fixed top-15 left-0 z-20"
      @click="$emit('closeMenu')"></div>

    <t-modal header="" :value="confirmationModal" @closed="onClosed" :clickToClose="false" :escToClose="false"
      variant="md">
      <div class="p-3">
        <p class="mb-1 text-black dark:text-white">
          {{ $i18n.t("logout_confirmation") }}
        </p>
      </div>

      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button type="button" variant="gray" @click="confirmationModal = false" class="mr-2">
            {{ $i18n.t("no") }}
          </t-button>
          <t-button type="button" @click="logout()">{{
              $i18n.t("logout")
          }}</t-button>
        </div>
      </template>
    </t-modal>
  </div>
</template>

<script>
import Item from "./VerticalNavMenuItem"
const notify = () => import("@/utils/notify.js")
export default {
  data() {
    return {
      company: {
        logo: "/static/logo_darkmode.png"
      },
      menu: [
        {
          id: 1,
          name: this.$i18n.t("new_post"),
          icon: "/static/icons/new_post.svg",
          url: "/new_post"
        },
        {
          id: 2,
          name: this.$i18n.t("home"),
          icon: "/static/icons/home.svg",
          url: "/home"
        },
        {
          id: 3,
          name: this.$i18n.t("radio"),
          icon: "/static/icons/radio.svg",
          url: "/radio"
        },
        {
          id: 4,
          name: this.$i18n.t("messages"),
          icon: "/static/icons/messages.svg",
          url: "/messages"
        },
        {
          id: 5,
          name: this.$i18n.t("chat_rooms"),
          icon: "/static/icons/chat_room.svg",
          url: "/chat_rooms"
        },
        {
          id: 6,
          name: this.$i18n.t("profile"),
          icon: "/static/icons/profile.svg",
          url: "/profile"
        },
        {
          id: 7,
          name: this.$i18n.t("videos"),
          icon: "/static/icons/video.svg",
          url: "/video"
        },
        {
          id: 8,
          name: this.$i18n.t("logout"),
          icon: "/static/icons/logout.svg",
          url: "/cerrar_sesion"
        }
      ],
      confirmationModal: false
    }
  },
  props: {
    menuExpanded: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Item
  },
  computed: {
    theme() {
      return this.$store.getters["getTheme"]
    },
    isGroupActive() {
      return (sidebarItem) => {
        const path = this.$route.fullPath
        let open = false
        let func = function (sidebarItem) {
          if (sidebarItem.submenu) {
            sidebarItem.submenu.forEach((item) => {
              if (path == item.url) {
                open = true
              } else if (item.submenu) {
                func(item)
              }
            })
          }
        }
        func(sidebarItem)
        return open
      }
    },
    publicRoom() {
      return this.$store.getters["public_room/getElement"]
    },
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    }
  },
  methods: {
    onClosed() {
      this.confirmationModal = false
    },
    functionMap(icon) {
      if (icon.includes("new_post")) return this.openModal()
      if (icon.includes("logout")) return this.logout()
      return this.$emit("closeMenu")
    },
    openModal() {
      this.$emit("update:newPostModal", true)
      this.$emit("closeMenu")
    },
    logout() {
      if (!this.confirmationModal) {
        this.confirmationModal = true
        return
      } else {
        this.$store
          .dispatch("auth/logout", this.$acl.change)
          .then((response) => {
            this.$socket.emit("user_disconnected")
            this.confirmationModal = false
            notify().then(({ notification }) => {
              notification("logout", 200, this.$snotify, this.$i18n.locale)
            })
          })
          .catch((error) => {
            console.log("Error:", error)
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
    }
  },
  watch: {
    $route() {
      if (this.publicRoom) {
        if (this.$route.path !== "/chat_rooms") {
          this.$socket.emit("leave_public_room", {
            id_user: this.loggedUser.id,
            id_public_room: this.publicRoom.id
          })
          this.$store.dispatch("public_room/setElement", null)
          this.$store.dispatch("public_room/setUserPublicRoom", null)
        }
      }
    }
  }
}
</script>
