var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLink && _vm.canSee && !_vm.submenu)?_c('router-link',{class:_vm.isActive ? 'menu-item active' : 'menu-item',attrs:{"to":_vm.url}},[(_vm.icon && !_vm.icon.includes('static'))?_c('icon',{staticClass:"inline-block mr-2 text-2xl",attrs:{"icon":_vm.icon}}):_c('span',[_c('img',{class:_vm.isActive
        ? 'inline-block sidebar-image active'
        : 'inline-block sidebar-image dark:filter invert',staticStyle:{"width":"26px","height":"26px","object-fit":"cover"},attrs:{"src":_vm.icon,"alt":"icon"}})]),_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.name))])],1):(!_vm.isLink && _vm.canSee && !_vm.submenu && _vm.icon.includes('new_post'))?_c('div',{staticClass:"menu-item-button bg-primary-1100 hover:bg-primary-1200",staticStyle:{"cursor":"pointer"}},[(_vm.icon && !_vm.icon.includes('static'))?_c('icon',{staticClass:"mr-2",attrs:{"icon":_vm.icon}}):_c('span',[_c('img',{class:_vm.isActive
        ? 'inline-block sidebar-image active'
        : 'inline-block sidebar-image filter invert',staticStyle:{"width":"26px","height":"26px","object-fit":"cover"},attrs:{"src":_vm.icon,"alt":"icon"}})]),_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.name))])],1):(!_vm.isLink && _vm.canSee && !_vm.submenu && !_vm.icon.includes('new_post'))?_c('div',{class:_vm.isActive ? 'menu-item active' : 'menu-item',staticStyle:{"cursor":"pointer"}},[(_vm.icon && !_vm.icon.includes('static'))?_c('icon',{staticClass:"mr-2",attrs:{"icon":_vm.icon}}):_c('span',[_c('img',{class:_vm.isActive
        ? 'inline-block sidebar-image active'
        : 'inline-block sidebar-image dark:filter invert',staticStyle:{"width":"26px","height":"26px","object-fit":"cover"},attrs:{"src":_vm.icon,"alt":"icon"}})]),_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.name))])],1):(_vm.submenu)?_c('div',[(_vm.oneItemCanSee(_vm.submenu))?_c('div',{staticClass:"flex items-center justify-between menu-item cursor-pointer",on:{"click":_vm.clickGroup}},[_c('div',{staticClass:"flex items-center"},[(_vm.icon)?_c('icon',{staticClass:"mr-2",attrs:{"icon":_vm.icon}}):_vm._e(),_vm._v(" "+_vm._s(_vm.name)+" ")],1),(_vm.submenu)?_c('div',{staticClass:"pr-2"},[(_vm.openItems)?_c('icon',{attrs:{"icon":"caret-up"}}):_c('icon',{attrs:{"icon":"caret-down"}})],1):_vm._e()]):_vm._e(),(_vm.submenu)?_c('div',{staticClass:"container-menu",style:(_vm.styleItems)},[_c('ul',{ref:"items",staticClass:"list-inside"},_vm._l((_vm.items),function(item,index){return _c('li',{key:'submenu-item-' + index},[_c('router-link',{class:_vm.itemIsActive(item.url)
            ? 'menu-item py-2 active'
            : 'menu-item py-2',attrs:{"to":item.url}},[_c('span',{staticClass:"pl-3 flex items-center"},[_c('span',{staticClass:"h-2 w-2 border border-gray-500 rounded-full mr-3"}),_vm._v(" "+_vm._s(item.name)+" ")])])],1)}),0)]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }