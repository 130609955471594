<template>
  <nav
    class="navbar-menu fixed rounded-lg mb-0 shadow-md bg-white dark:bg-jevi-dark h-auto z-20 top-0 right-0 w-full px-4 py-3">
    <div class="w-full flex justify-center lg:justify-end items-center h-10" style="position: relative">
      <div class="hidden lg:flex items-center" style="
          position: absolute;
          left: 50%;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
        ">
        <h2 class="my-auto text-3xl ml-2 text-black dark:text-white select-none">
          Jevi App
        </h2>
      </div>

      <div class="flex relative mr-0 lg:mr-4">
        <p class="text-center text-black dark:text-white text-sm">
          {{ $t('already_have_an_account') }}
          <router-link to="/login" class="text-primary-900">
            {{ $t('login2') }}
          </router-link>
          {{ " " + $t('or') + " " }}
          <router-link to="/signup" class="text-primary-900">
            {{ $t('signup') }}
          </router-link>
        </p>
      </div>
    </div>
  </nav>
</template>

<script>
import Notifications from "../notifications/Notifications.vue"
import Searchbar from "@/components/Searchbar"

const notify = () => import("@/utils/notify.js")
export default {
  name: "navbar",
  components: {
    Notifications,
    Searchbar
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    },
    countNotificationsUnread() {
      return this.$store.getters["auth/countNotificationsUnread"]
    },
    searchModal() {
      return this.$store.getters["getSearchModal"]
    }
  },

  methods: {
    toggleSearchModal() {
      this.$store.dispatch("setSearchModal", !this.searchModal)
    },
    logout() {
      this.$store
        .dispatch("auth/logout", this.$acl.change)
        .then((response) => {
          notify().then(({ notification }) => {
            this.$socket.emit("user_disconnected")
            notification("logout", 200, this.$snotify, this.$i18n.locale)
          })
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    canSee(url) {
      this.$acl.check(this.$store.state.auth.userRole)
      return this.$acl.check(this.$router.match(url).meta.rule)
    }
  }
}
</script>

<style lang="scss">
.navbar-menu {
  -webkit-box-shadow: 0px 15px 36px -8px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 15px 36px -8px rgba(0, 0, 0, 0.3);
  border-top: 0px;
  width: 100%;
}

.notification-indicator {
  height: 25px;
  width: 25px;
  line-height: 25px;
}

.notification-indicator-xl {
  height: 40px;
  width: 40px;
  line-height: 40px;
}
</style>
