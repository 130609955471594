<template>
  <nav
    class="navbar-menu fixed rounded-lg mb-0 shadow-md bg-white dark:bg-jevi-dark h-auto z-20 top-0 right-0 w-full px-4 py-3"
  >
    <div
      class="w-full flex justify-between lg:justify-end"
      style="position: relative"
    >
      <button
        style="margin-top: 9px"
        class="nav-menu-button lg:hidden"
        aria-label="Desplegar menu"
        @click="$emit('collapse')"
      >
        <img
          src="/static/icons/menu.svg"
          alt="Menu icon"
          class="mx-auto"
          style="cursor: pointer; width: 25px"
        />
      </button>
      <div
        class="hidden lg:flex items-center"
        style="
          position: absolute;
          left: 50%;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
        "
      >
        <img
          v-if="$route.meta.icon"
          :src="$route.meta.icon"
          class="inline-block primary-color-svg"
          alt="icon"
          style="width: 34px"
        />
        <h2
          v-if="$route.meta.pageTitle"
          class="my-auto text-3xl ml-2 text-black dark:text-white select-none"
        >
          {{ $i18n.t($route.meta.pageTitle) }}
        </h2>
      </div>
      <div class="flex relative mr-0 lg:mr-4">
        <t-button
          variant="empty"
          class="block w-full px-3 py-2 transition duration-100 ease-in-out border border-1 rounded-lg shadow-sm hover:border-primary-800 hover:border-2 hover:shadow-md hover:outline-none disabled:opacity-50 disabled:cursor-not-allowed bg-white dark:bg-gray-800 dark:text-white rounded-3xl border-0 active:border-gray-500 placeholder-gray-400 shadow-lg pr-24 sm:pr-40"
          style="height: fit-content"
          @click="toggleSearchModal"
        >
          {{ $i18n.t("search") }}
        </t-button>
        <img
          src="/static/icons/search.svg"
          class="absolute top-1/3 right-4 dark:filter invert"
          alt="Search icon"
          width="18px"
          height="18px"
          style="
            filter: invert(57%) sepia(47%) saturate(6150%) hue-rotate(217deg)
              brightness(94%) contrast(97%);
          "
        />
      </div>
      <div class="flex">
        <div class="flex items-center">
          <t-dropdown
            text=""
            id="notifications"
            :classes="{
              dropdown:
                'w-80 bg-white left-auto right-0 top-4 mt-3 overflow-auto'
            }"
          >
            <div
              slot="trigger"
              slot-scope="{
                mousedownHandler,
                focusHandler,
                blurHandler,
                keydownHandler,
                isShown
              }"
            >
              <button
                id="notifications-menu"
                class="mr-4 flex items-center transition duration-75 ease-in-out rounded-full text-black dark:text-white text-xl focus:outline-none focus:shadow-solid"
                :class="{ ' ': isShown }"
                :aria-label="$i18n.t('notifications')"
                aria-haspopup="true"
                @mousedown="mousedownHandler"
                @focus="focusHandler"
                @blur="blurHandler"
                @keydown="keydownHandler"
              >
                <img
                  src="/static/icons/bell.svg"
                  alt="Bell icon"
                  width="20px"
                  class="dark:filter invert"
                />
                <sup
                  v-if="countNotificationsUnread"
                  class="notification-indicator bg-primary-1100 text-white border border-primary-1200 rounded-full -ml-2 -mt-2 font-bold text-xs"
                  :class="
                    countNotificationsUnread.toString().includes('K')
                      ? 'notification-indicator-xl'
                      : ''
                  "
                  >{{ countNotificationsUnread }}</sup
                >
              </button>
            </div>
            <div class="py-1 rounded-md shadow-xs" slot-scope="{ hide }">
              <Notifications :hide="hide" />
            </div>
          </t-dropdown>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import Notifications from "../notifications/Notifications.vue"
import Searchbar from "@/components/Searchbar"

const notify = () => import("@/utils/notify.js")
export default {
  name: "navbar",
  components: {
    Notifications,
    Searchbar
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    },
    countNotificationsUnread() {
      return this.$store.getters["auth/countNotificationsUnread"]
    },
    searchModal() {
      return this.$store.getters["getSearchModal"]
    }
  },

  methods: {
    toggleSearchModal() {
      this.$store.dispatch("setSearchModal", !this.searchModal)
    },
    logout() {
      this.$store
        .dispatch("auth/logout", this.$acl.change)
        .then((response) => {
          notify().then(({ notification }) => {
            this.$socket.emit("user_disconnected")
            notification("logout", 200, this.$snotify, this.$i18n.locale)
          })
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    canSee(url) {
      this.$acl.check(this.$store.state.auth.userRole)
      return this.$acl.check(this.$router.match(url).meta.rule)
    }
  }
}
</script>

<style lang="scss">
.navbar-menu {
  -webkit-box-shadow: 0px 15px 36px -8px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 15px 36px -8px rgba(0, 0, 0, 0.3);
  border-top: 0px;
  width: 100%;
}

.notification-indicator {
  height: 25px;
  width: 25px;
  line-height: 25px;
}

.notification-indicator-xl {
  height: 40px;
  width: 40px;
  line-height: 40px;
}
</style>
