<template>
  <div style="z-index: 40 !important">
    <h3 class="px-3 text-primary-1200 font-bold py-2 border-b">
      {{ $i18n.t("notifications") }}
      <span class="text-sm text-white bg-primary-1000 rounded-full px-2 float-right">{{ countNotificationsUnread
      }}</span>
    </h3>
    <div v-if="!countNotificationsUnread" class="p-2 text-primary-1200 m-2 rounded-md shadow-md">
      <strong> {{ $i18n.t("up_to_date") }}</strong>
      {{ $i18n.t("no_unread_notifications") }}.
    </div>
    <div class="max-h-80 overflow-y-auto">
      <div v-for="group in notifications_data" :key="'notification-' + group.date_utc.toString()">
        <p class="text-black text-left ml-2 text-sm text-primary-1200 font-bold">
          {{
              new Intl.DateTimeFormat(navigator_language, {
                year: "numeric",
                month: "long",
                day: "numeric"
              }).format(new Date(group.date_utc + "Z"))
          }}
        </p>
        <router-link v-for="item in group.notifications" :key="`${group.date_utc.toString()}-${item.id}`"
          :to="item.url">
          <button
            :class="`block w-full text-left px-4 py-2 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out bg-gray-50 hover:bg-gray-100 focus:outline-none focus:bg-gray-100`"
            role="menuitem" @click="viewNotification(item)" tag="button">
            <div class="flex justify-between items-center">
              <div class="flex">
                <!--<template v-if="item.icon">
                  <div
                    v-if="!item.icon.includes('static')"
                    :class="`mr-1 p-2 justify-items-center flex text-gray-500 float-left`"
                  >
                    <icon :icon="item.icon" />
                  </div>
                  <img
                    v-else
                    class="mr-1"
                    :src="item.icon"
                    alt="Notification icon"
                    style="width: 25px; height: 25px"
                  />
                </template>-->
                <img v-if="item.user_image" style="
                    width: 30px;
                    height: 30px;
                    border-radius: 100%;
                    aspect-ratio: 1;
                    object-fit: cover;
                  " :src="item.user_image" alt="User image" />
                <small :class="item.viewed === 0 ? 'font-bold ml-2' : 'ml-2'">
                  {{
                      item.title +
                      " " +
                      (item.type_child == 'profile' ? item.description : "") +
                      (item.description && item.type_child != 'profile' ? item.description.toLowerCase() : "")
                  }}
                </small>
              </div>

              <template v-if="item.post && ![3, 4, 5].includes(item.post.type)" class="float-right">
                <img style="
                    width: 50px;
                    height: 50px;
                    aspect-ratio: 1;
                    object-fit: cover;
                  " :src="item.preview" alt="Preview" />
              </template>
              <template v-if="item.post && item.post.type === 5">
                <video height="50px" width="50px" class="mx-auto object-cover" style="aspect-ratio: 1"
                  :src="item.post.imgkit_url_video || item.post.url_video" playsinline autoplay loop muted
                  controlsList="nodownload" />
              </template>

              <template v-if="item.highlight" class="float-right">
                <img style="
                    width: 50px;
                    height: 50px;
                    aspect-ratio: 1;
                    object-fit: cover;
                  " :src="item.preview" alt="Preview" />
              </template>
            </div>

            <div v-if="
              item.follow_request && item.follow_request.status_request == 1
            " class="flex justify-end items-center">
              <t-button class="mr-2" variant="gray-sm" :style="{ 'font-size': '14px' }"
                @click="updateFollowRequest(item, item.follow_request, 3)">
                {{ $i18n.t("reject") }}
              </t-button>

              <t-button variant="sm" :style="{ 'font-size': '14px' }"
                @click="updateFollowRequest(item, item.follow_request, 2)">
                {{ $i18n.t("accept") }}
              </t-button>
            </div>

            <small class="float-right">
              {{
                  new Intl.DateTimeFormat(navigator_language, {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric"
                  }).format(new Date(item.updated_at + "Z"))
              }}</small>
          </button>
        </router-link>
      </div>
    </div>
    <div class="w-full p-1">
      <t-button class="w-full bg-primary-1000" :disabled="!countNotificationsUnread" @click="viewAllNotification">{{
          $i18n.t("mark_all_as_read")
      }}</t-button>
    </div>
  </div>
</template>
<script>
const notify = () => import("@/utils/notify.js")

export default {
  props: ["hide"],
  data() {
    return {
      typeNotifications: {
        room_message: {
          icon: "/static/icons/room.svg",
          url: "/messages"
        },
        room: {
          icon: "/static/icons/room.svg",
          url: "/messages"
        },
        chat: {
          icon: "/static/icons/messages.svg",
          url: "/messages"
        },
        chat_message: {
          icon: "/static/icons/messages.svg",
          url: "/messages"
        },
        profile: {
          icon: "/static/icons/profile.svg",
          url: "/profile"
        },
        follow_request: {
          icon: "/static/icons/follow.svg",
          url: "/user"
        },
        like: {
          icon: "/static/icons/jevi.svg",
          url: "/post"
        },
        comment: {
          icon: "/static/icons/comment.svg",
          url: "/post"
        },
        post: {
          icon: "/static/icons/new_post.svg",
          url: "/post"
        },
        highlight: {
          icon: "/static/icons/new_post.svg",
          url: "/home"
        },
        video: {
          icon: "/static/icons/video.svg",
          url: "/post"
        },
        station: {
          icon: "/static/icons/radio.svg",
          url: "/"
        },
        story: {
          icon: "/static/icons/moment.svg",
          url: "/user"
        }
      },
      notifications_data: [],
      navigator_language: navigator.language,
      isLoadingx: false
    }
  },
  computed: {
    notifications() {
      return this.$store.getters["auth/getNotifications"]
    },
    countNotificationsUnread() {
      return this.$store.getters["auth/countNotificationsUnread"]
    },
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    }
  },
  methods: {
    configNotification(notification) {
      if (notification.user) {
        this.$store.dispatch('auth/setVerified', notification.user.verified)
      }

      const lang = this.$i18n.locale

      let config = this.typeNotifications[notification.type_child]

      config.title = lang === "es" ? notification.es_title : notification.en_title

      config.id = notification.id

      if (
        notification.type_child === "chat" ||
        notification.type_child === "chat_message"
      ) {
        config.title = notification.user_notification.full_name
      }

      if (notification.type_child === "room_message") {
        config.title = notification.room.name
      }

      config.description =
        lang === "es"
          ? notification.es_description
          : notification.en_description

      config.user_image = notification.user_notification
        ? notification.user_notification.profile_picture
        : "/static/profile-default.jpg"

      if (notification.user_notification) {
        if (notification.user_notification.imgkit_profile_picture) {
          config.user_image =
            notification.user_notification.imgkit_profile_picture +
            "?tr=w-96,h-96"
        } else {
          config.user_image = notification.user_notification.profile_picture
        }
      }

      if (!notification.user && !notification.user_notification) {
        config.user_image = "/static/logo_square.png"
      }

      if (notification.type_child === "profile")
        config.user_image = '/static/logo_square.png'

      if (notification.type_child === "room_message")
        config.user_image =
          notification.room.profile_picture ||
          notification.user_notification.profile_picture

      if (
        notification.type_child === "post" ||
        notification.type_child === "comment"
      ) {
        config.post = notification.post
        if (notification.post.type === 1) {
          config.preview = notification.post.url_picture
          if (notification.post.imgkit_url_picture) {
            config.preview =
              notification.post.imgkit_url_picture + "?tr=w-96,h-96"
          }
        }

        config.url = `/post/${notification.post.id}`
      }

      if (notification.type_child === "highlight") {
        config.highlight = notification.highlight
        config.preview = notification.highlight.url_image

        if (notification.highlight.imgkit_url_image) {
          config.preview = notification.highlight.imgkit_url_image + "?tr=w-96,h-96"
        }
      }

      if (
        notification.type_child === "follow_request" ||
        notification.type_child === "story"
      )
        config.url = `/user/${notification.user_notification.username}`

      if (
        notification.type_child === "profile"
      )
        config.url = `/profile`

      if (notification.type_child === "follow_request")
        config.follow_request = notification.follow_request

      config.updated_at = notification.updated_at

      if (notification.type_child === "station") {
        config.user_image = null
      }

      config.type_child = notification.type_child
      config.viewed = notification.viewed
      return config
    },
    async requestYoutubeThumbnail(notification, index) {
      await fetch(
        `https://youtube.com/oembed?url=${notification.post.url_video}&format=json`
      )
        .then((response) => (response ? response.json() : {}))
        .then((data) => {
          let notification_index = this.notifications_data[
            index
          ].notifications.findIndex((x) => x.id === notification.id)
          this.notifications_data[index].notifications[
            notification_index
          ].preview = data.thumbnail_url
        })
    },
    viewNotification(item) {
      this.$store
        .dispatch("auth/viewNotification", { id: item.id })
        .catch(() => { })
      this.hide()
    },
    viewAllNotification() {
      this.$store
        .dispatch("auth/viewAllNotification")
        .then(async (response) => {
          this.isLoading = false
          notify().then(({ notification }) => {
            notification(
              "notificationReaded",
              response.code,
              this.$snotify,
              this.$i18n.locale
            )
          })
        })
        .catch((error) => {
          this.isLoading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    updateFollowRequest(item, follow_request, status) {
      if (status == 2) {
        console.log("ACCEPTED!")

        this.$store
          .dispatch("global/update", {
            payload: {
              id: follow_request.id,
              status_request: status
            },
            route: "/follow_request/update",
            module: null
          })
          .then(async (response) => {
            this.isLoadingx = false
            item.follow_request.status_request = 2

            notify().then(({ notification }) => {
              notification(
                "followRequestAccepted",
                response.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
          .catch((error) => {
            this.isLoadingx = false
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      } else {
        console.log("REJECTED!")

        this.$store
          .dispatch("global/create", {
            payload: {
              id_user_sent: follow_request.id_user_sent,
              id_user_received: this.loggedUser.id
            },
            route: "/follow_request/save",
            module: null
          })
          .then((response) => {
            this.isLoadingx = false
            item.follow_request.status_request = 3

            notify().then(({ notification }) => {
              notification(
                "followRequestRejected",
                response.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
          .catch((error) => {
            this.isLoadingx = false
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
    }
  },
  async created() {
    let notifications_data = this.$store.getters["auth/getNotifications"]
    this.notifications_data = []

    for (let index in notifications_data) {
      this.notifications_data.push({
        date_utc: notifications_data[index].date_utc,
        notifications: []
      })

      for (let notification of notifications_data[index].notifications) {
        let aux_data = this.configNotification(notification)
        this.notifications_data[index].notifications.push({
          ...aux_data
        })

        if (notification.post && notification.post.type === 2)
          await this.requestYoutubeThumbnail(notification, index)
      }
    }
  },
  watch: {
    async notifications() {
      let notifications_data = this.$store.getters["auth/getNotifications"]
      this.notifications_data = []
      for (let index in notifications_data) {
        this.notifications_data.push({
          date_utc: notifications_data[index].date_utc,
          notifications: []
        })

        for (let notification of notifications_data[index].notifications) {
          let aux_data = this.configNotification(notification)
          this.notifications_data[index].notifications.push({
            ...aux_data
          })

          if (notification.post && notification.post.type === 2)
            await this.requestYoutubeThumbnail(notification, index)
        }
      }
    }
  }
}
</script>
