<template>
  <router-link v-if="isLink && canSee && !submenu" :class="isActive ? 'menu-item active' : 'menu-item'" :to="url">
    <icon v-if="icon && !icon.includes('static')" :icon="icon" class="inline-block mr-2 text-2xl"></icon>
    <span v-else>
      <img :src="icon" :class="
        isActive
          ? 'inline-block sidebar-image active'
          : 'inline-block sidebar-image dark:filter invert'
      " style="width: 26px; height: 26px; object-fit: cover" alt="icon" />
    </span>

    <span class="ml-3">{{ name }}</span>
  </router-link>

  <div v-else-if="!isLink && canSee && !submenu && icon.includes('new_post')"
    class="menu-item-button bg-primary-1100 hover:bg-primary-1200" style="cursor: pointer">
    <icon v-if="icon && !icon.includes('static')" :icon="icon" class="mr-2"></icon>
    <span v-else>
      <img :src="icon" :class="
        isActive
          ? 'inline-block sidebar-image active'
          : 'inline-block sidebar-image filter invert'
      " style="width: 26px; height: 26px; object-fit: cover" alt="icon" />
    </span>

    <span class="ml-3">{{ name }}</span>
  </div>

  <div v-else-if="!isLink && canSee && !submenu && !icon.includes('new_post')"
    :class="isActive ? 'menu-item active' : 'menu-item'" style="cursor: pointer">
    <icon v-if="icon && !icon.includes('static')" :icon="icon" class="mr-2"></icon>
    <span v-else>
      <img :src="icon" :class="
        isActive
          ? 'inline-block sidebar-image active'
          : 'inline-block sidebar-image dark:filter invert'
      " style="width: 26px; height: 26px; object-fit: cover" alt="icon" />
    </span>

    <span class="ml-3">{{ name }}</span>
  </div>

  <div v-else-if="submenu">
    <div class="flex items-center justify-between menu-item cursor-pointer" @click="clickGroup"
      v-if="oneItemCanSee(submenu)">
      <div class="flex items-center">
        <icon v-if="icon" :icon="icon" class="mr-2"></icon>
        {{ name }}
      </div>
      <div v-if="submenu" class="pr-2">
        <icon v-if="openItems" icon="caret-up" />
        <icon v-else icon="caret-down" />
      </div>
    </div>

    <div v-if="submenu" :style="styleItems" class="container-menu">
      <ul class="list-inside" ref="items">
        <li v-for="(item, index) in items" :key="'submenu-item-' + index">
          <router-link :class="
            itemIsActive(item.url)
              ? 'menu-item py-2 active'
              : 'menu-item py-2'
          " :to="item.url">
            <span class="pl-3 flex items-center">
              <span class="h-2 w-2 border border-gray-500 rounded-full mr-3" />
              {{ item.name }}
            </span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
      default: "Menu item"
    },
    url: {
      type: String,
      required: true,
      default: ""
    },
    icon: {
      type: String,
      default: "user"
    },
    submenu: {
      type: Array,
      required: false
    },
    open: {
      type: Boolean,
      default: false
    },
    isLink: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      maxHeight: "0px",
      openItems: false
    }
  },
  computed: {
    isActive() {
      return this.itemIsActive(this.url)
    },
    canSee() {
      return this.itemCanSee(this.url)
    },
    items() {
      return this.submenu
        ? this.submenu.filter((item) => this.itemCanSee(item.url))
        : []
    },
    styleItems() {
      return {
        maxHeight: this.maxHeight,
        display: this.maxHeight === "0px" ? "none" : "block"
      }
    }
  },
  watch: {
    $route() {
      let scrollHeight = this.scrollHeight
      if (this.openItems && !this.open) {
        this.maxHeight = `${scrollHeight}px`
        // eslint-disable-next-line vue/no-async-in-computed-properties
        setTimeout(() => {
          this.maxHeight = `${0}px`
          return
        }, 50)
      } else if (this.open) {
        this.maxHeight = `${scrollHeight}px`
        setTimeout(() => {
          this.maxHeight = "none"
          return
        }, 50)
      }
    },
    maxHeight() {
      this.openItems = this.maxHeight != "0px"
    }
  },
  methods: {
    itemCanSee(url) {
      this.$acl.check(this.$store.state.auth.userRole)
      return this.$acl.check(this.$router.match(url).meta.rule)
    },
    itemIsActive(url) {
      return this.$route.path.includes(url)
    },
    clickGroup() {
      let thisScrollHeight = this.$refs.items.scrollHeight
      if (this.maxHeight == "0px") {
        this.maxHeight = `${thisScrollHeight}px`
        setTimeout(() => {
          this.maxHeight = "none"
        }, 100)
      } else {
        this.maxHeight = `${thisScrollHeight}px`
        setTimeout(() => {
          this.maxHeight = `${0}px`
        }, 50)
      }

      this.$parent.$children.map((child) => {
        if (child.isGroupActive) {
          if (child !== this && !child.open && child.maxHeight != "0px") {
            setTimeout(() => {
              child.maxHeight = `${0}px`
            }, 50)
          }
        }
      })
    },
    oneItemCanSee(items) {
      this.$acl.check(this.$store.state.auth.userRole)
      for (const item of items) {
        if (this.$acl.check(this.$router.match(item.url).meta.rule)) {
          return true
        }
      }
      return false
    }
  },
  mounted() {
    this.openItems = this.open
    if (this.open) {
      this.maxHeight = "none"
    }
  }
}
</script>
