<template>
  <div>
    <t-modal header="" :value="show" @closed="closeModal" variant="story">
      <t-card :variant="theme === 'dark' ? 'dark-story' : 'light-story'">
        <div class="story-container">
          <div class="story-content">
            <div class="relative h-full">
              <div class="absolute top-4 w-full px-3" style="z-index: 3">
                <div class="grid" :style="`grid-template-columns: repeat(${stories.length}, minmax(0, 1fr));`">
                  <div class="bg-gray-400 h-1" style="margin: 0 1.5px; filter: drop-shadow(0 0 0.7px #000)"
                    v-for="(story, index) in stories" :key="story.id">
                    <div v-if="currentStoryIndex === index" class="bg-gray-100 h-1" :style="`width: ${percent()}%; 
                     transition-timing-function: ease-out;
                      -webkit-transition: ${percent() === 0 ? '' : 'width 0.5s'
                      };
                      -moz-transition: ${percent() === 0 ? '' : 'width 0.5s'};
                      -ms-transition: ${percent() === 0 ? '' : 'width 0.5s'};
                      -o-transition: ${percent() === 0 ? '' : 'width 0.5s'};
                      transition: ${percent() === 0 ? '' : 'width 0.5s'};
                    
                    `"></div>
                    <div v-else class="bg-gray-100 h-1" :style="
                      story.viewed === 1 && index < currentStoryIndex
                        ? 'width: 100%'
                        : 'width: 0%'
                    "></div>
                  </div>
                </div>
              </div>

              <div class="w-full px-6 absolute top-8" style="filter: drop-shadow(0 0 0.7px #000); z-index: 3">
                <div class="w-full flex justify-between">
                  <div class="flex">
                    <div class="w-16 h-16">
                      <profile-picture :data="storiesUser" :sizePx="128" />
                    </div>

                    <div class="ml-3 my-auto">
                      <p class="text-black dark:text-white text-sm sm:text-md font-bold" @click="openProfile"
                        style="display: flex; align-items: center; cursor: pointer">
                        {{ storiesUser.username }}
                        <span class="ml-1" v-if="storiesUser.verified == 1">
                          <img src="/static/user_verified.png" style="{ display: flex; height: 16px; width: 16px; }" />
                          <!--<font-awesome-icon class="mt-2 mb-0 my-0" icon="check-circle" style="color: #6a71ec; font-size: 16px" />-->
                        </span>
                      </p>
                      <p v-if="currentStory && currentStory.created_at"
                        class="text-black dark:text-white text-sm sm:text-md">
                        {{ currentStory.created_at | fromNow }}
                      </p>
                    </div>
                  </div>

                  <t-dropdown v-if="storiesUser.id === loggedUser.id">
                    <div slot="trigger" slot-scope="{
                        mousedownHandler,
                        focusHandler,
                        blurHandler,
                        keydownHandler
                      }" class="flex">
                      <button
                        class="flex items-center justify-center flex-shrink-0 px-3 py-2 text-black dark:text-white bg-transparent rounded-r"
                        @mousedown="mousedownHandler" @focus="focusHandler" @blur="blurHandler"
                        @keydown="keydownHandler">
                        <icon icon="ellipsis-v" class="mr-2 my-auto text-black dark:text-white text-xl sm:text-2xl"
                          style="cursor: pointer"></icon>
                      </button>
                    </div>

                    <div slot-scope="{ blurHandler }">
                      <button
                        class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
                        role="menuitem" @blur="blurHandler" @click="deleteStory">
                        <img src="/static/icons/delete.svg" class="mr-1 inline-block" alt="Delete icon" style="
                            width: 20px;
                            height: 20px;
                            filter: invert(22%) sepia(80%) saturate(1684%)
                              hue-rotate(326deg) brightness(95%) contrast(92%);
                          " />
                        {{ $i18n.t("delete") }}
                      </button>
                    </div>
                  </t-dropdown>
                </div>
              </div>

              <span v-if="hasPrevStory" class="story-nav left" @click="changeStory('backwards', true)"
                style="z-index: 3">
                <icon icon="angle-left" />
              </span>

              <span v-if="hasNextStory && allowNextStory" class="story-nav right" @click="changeStory('forward', true)"
                style="z-index: 3">
                <icon icon="angle-right" />
              </span>

              <div class="flex items-center justify-center h-full">
                <img v-if="currentStory.type === 1" class="w-1/3 mx-auto object-cover" :src="
                  theme === 'dark'
                    ? '/static/loading_darkmode.gif'
                    : '/static/loading_lightmode.gif'
                " alt="story-loader" ref="story-loader" />
                <img v-if="currentStory.type === 1" :alt="currentStory.text"
                  class="hidden mx-auto object-cover h-full w-full select-none" :src="picture(currentStory)"
                  :ref="'story-' + currentStory.id" @loadstart="showImageLoader" @error="showImageLoader"
                  @load="onImageLoad" @mousedown="toggleTimer('pause')" @mouseup="toggleTimer('resume')" />
              </div>

              <video v-if="currentStory.type === 2" id="story-video" class="mx-auto w-full" :class="
                currentStory.aspect_ratio < 1 ? 'h-full object-cover' : ''
              " :src="currentStory.imgkit_url_story || currentStory.url_story" style="
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  -webkit-transform: translateX(-50%) translateY(-50%);
                  transform: translateX(-50%) translateY(-50%);
                " playsinline autoplay @timeupdate="updateProgress" @pause="onVideoPause" @play="onVideoPlay"
                @mousedown="toggleTimer('pause')" @mouseup="toggleTimer('resume')" />
            </div>
          </div>

          <div class="story-footer" style="z-index: 3">
            <div class="w-5/6 px-1 py-1 my-auto max-h-full overflow-y-auto">
              <p class="text-black dark:text-white text-xs lg:text-sm select-none"
                v-html="$detectHashtagsAndUsernames(currentStory.text)" @click="handleProcessedTextClick"></p>
            </div>
            <div class="w-1/6 mx-auto my-auto">
              <div v-if="storiesUser.id === loggedUser.id" class="flex flex-col justify-center items-center">
                <div class="flex justify-center">
                  <img :src="
                    currentStory.liked === 0
                      ? '/static/icons/jevi.svg'
                      : '/static/icons/jeviFilled.svg'
                  " alt="Jevi button" width="24px" class="mr-1 mx-auto my-auto select-none" style="cursor: pointer"
                    :class="
                      currentStory.liked === 0 ? 'dark:filter invert' : ''
                    " @click="like" />
                  <p class="my-auto text-black dark:text-white text-xs">
                    {{ calculateHighNumber(currentStory.total_likes) }}
                  </p>
                </div>
                <div class="flex justify-center">
                  <img src="/static/icons/views.svg" alt="Views icon" width="20px"
                    class="mr-1 my-auto dark:filter invert" />

                  <p class="my-auto text-black dark:text-white text-xs">
                    {{ calculateHighNumber(currentStory.total_views) }}
                  </p>
                </div>
              </div>
              <div v-else class="flex flex-row justify-center items-center">
                <div class="flex justify-center">
                  <img :src="
                    currentStory.liked === 0
                      ? '/static/icons/jevi.svg'
                      : '/static/icons/jeviFilled.svg'
                  " alt="Jevi button" width="24px" class="mr-1 mx-auto my-auto select-none" style="cursor: pointer"
                    :class="
                      currentStory.liked === 0 ? 'dark:filter invert' : ''
                    " @click="like" />
                </div>
                <div class="flex justify-center">
                  <img src="/static/icons/comment.svg" alt="Comments button" width="24px"
                    class="mr-1 mx-auto my-auto select-none dark:filter invert" style="cursor: pointer" @click="
  toggleTimer('pause')
                      commentModal = true
                    " />
                </div>
              </div>
            </div>
          </div>
        </div>
      </t-card>
    </t-modal>

    <t-modal :header="$i18n.t('send_comment')" :value="commentModal" @closed="closeCommentModal" :clickToClose="false"
      :escToClose="false" variant="lg">
      <t-input-group :feedback="
        errors.first('commentStoryMsg') ? errors.first('commentStoryMsg') : ''
      " :variant="errors.first('commentStoryMsg') ? 'danger' : ''" class="my-5 relative">
        <t-textarea v-validate="'simple_required|min:1|max:1000'" data-vv-validate-on="input" name="commentStoryMessage"
          v-model="commentMsg" :placeholder="$i18n.t('add_comment')" id="comment-textarea">
        </t-textarea>
      </t-input-group>

      <div class="flex justify-center">
        <t-button type="button" @click="comment()" :disabled="commentLoading">
          <span v-if="commentLoading"><t-loader extraClass="border-orange-primary" /></span>
          <span v-else class="text-white">{{ $i18n.t("send") }}</span>
        </t-button>
      </div>
    </t-modal>

    <t-modal :header="''" :value="storyDeletionConfirmation" @closed="storyDeletionModalClosed" variant="md">
      <div class="p-3">
        <p class="mb-1 text-black dark:text-white">
          {{ $i18n.t("story_delete_confirmation") }}
        </p>
      </div>

      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button type="button" variant="gray" @click="storyDeletionConfirmation = false" class="mr-2">
            {{ $i18n.t("no") }}
          </t-button>
          <t-button type="button" @click="deleteStory">{{
              $i18n.t("delete")
          }}</t-button>
        </div>
      </template>
    </t-modal>
  </div>
</template>

<script>
import ProfilePicture from "@/components/user/ProfilePicture.vue"
const notify = () => import("@/utils/notify.js")
const DEFAULT_STORY_DURATION = 5000
export default {
  components: {
    ProfilePicture
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasPrevStory() {
      if (this.stories[this.currentStoryIndex - 1]) return true
      const index = this.userStories.findIndex(
        (x) => x.id === this.storiesUser.id
      )
      if (index !== -1) {
        if (this.userStories[index - 1]) return true
      }
      return false
    },
    hasNextStory() {
      console.log(this.stories)
      if (this.stories[this.currentStoryIndex + 1]) return true
      const index = this.userStories.findIndex(
        (x) => x.id === this.storiesUser.id
      )
      if (index !== -1) {
        if (this.userStories[index + 1]) return true
      }
      return false
    },
    theme() {
      return this.$store.getters["getTheme"]
    },
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    },
    stories() {
      return this.$store.getters["story/getItem"]
    },
    storiesUser() {
      return this.$store.getters["story/getItemUser"]
    },
    userStories() {
      return this.$store.getters["story/getAllUnpaginated"]
    },
    userView() {
      if (
        this.$route.name === "User Profile" ||
        this.$route.name === "Profile"
      ) {
        return true
      }

      return false
    }
  },
  data() {
    return {
      videoFormats: [".mp4", ".m4v", ".mpeg", ".wmv", "avi"],
      imageFormats: [".png", ".jpeg", ".jpg", ".gif", ".bmp"],
      currentStoryIndex: null,
      currentStory: {},
      time: null,
      intervalID: null,
      storyDeletionConfirmation: false,
      storyDuration: DEFAULT_STORY_DURATION, // Expressed in milliseconds for more precise countdown,
      wasPaused: false,
      commentModal: false,
      commentMsg: "",
      commentLoading: false,
      allowNextStory: true
    }
  },
  methods: {
    calculateHighNumber(number) {
      number = number
      if (isNaN(number)) return 0
      if (Number(number) > 1000000) return (number / 1000000).toFixed(2) + "M"
      if (Number(number) > 1000) {
        return (number / 1000).toFixed(2) + "K"
      } else {
        return number
      }
    },
    storyDeletionModalClosed() {
      this.storyDeletionConfirmation = false
    },
    deleteStory() {
      if (!this.storyDeletionConfirmation) {
        this.toggleTimer("pause")
        this.storyDeletionConfirmation = true
        return
      }
      this.$store
        .dispatch("global/changeStatus", {
          payload: { id: this.currentStory.id, status: 2 },
          route: "/story"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification(
              "story-delete",
              response.code,
              this.$snotify,
              this.$i18n.locale
            )
          })
          if (response.code === 200) {
            this.$store
              .dispatch("story/deleteElement", this.currentStory)
              .then(() => {
                this.$store.dispatch("auth/getUserLogged")
                this.storyDeletionModalClosed()
                this.changeStory("forward", true)
              })
          }
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    percent() {
      if (!this.time || !this.storyDuration) return 0
      return 100 - Math.floor((this.time / this.storyDuration) * 100)
    },
    openProfile() {
      this.$emit("openProfile", this.storiesUser)
    },
    closeModal() {
      this.$emit("update:show", false)
    },
    picture(element) {
      return element.imgkit_url_story
        ? element.imgkit_url_story
        : element.url_story
    },
    handleProcessedTextClick(e) {
      if (e.target.matches(".post-hashtag")) {
        this.$store
          .dispatch("global/getItems", {
            route: "/hashtag/all",
            module: null,
            noPaginate: true,
            isMaster: true,
            params: {
              //query: e.target.innerHTML.slice(1),
              name: e.target.innerHTML,
              limit: 1,
              active: 1
            }
          })
          .then((response) => {
            if (response.data[0]) {
              this.$router.push("/post_by_hashtag/" + response.data[0].name.slice(1))
              this.$emit("update:show", false)
            } else {
              notify().then(({ notification }) => {
                notification("error", 404, this.$snotify, this.$i18n.locale)
              })
            }
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
      if (e.target.matches(".post-username")) {
        this.$store
          .dispatch("global/getItems", {
            route: "/user/all",
            module: null,
            noPaginate: true,
            params: {
              username: e.target.innerHTML.slice(1),
              limit: 1,
              active: 1
            }
          })
          .then((response) => {
            if (response.data[0]) {
              this.openProfile(response.data[0])
            } else {
              notify().then(({ notification }) => {
                notification("error", 404, this.$snotify, this.$i18n.locale)
              })
            }
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
    },
    like() {
      let element = this.currentStory
      element.liked = element.liked === 1 ? 0 : 1
      this.$store.dispatch("story/updateElement", element)
      this.$store
        .dispatch("global/create", {
          payload: {
            id_user: this.loggedUser.id,
            type_child: "story",
            id_child: element.id
          },
          route: "/like/save",
          module: null
        })
        .then(() => { })
        .catch((error) => {
          console.log("story like error:", error)
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    comment() {
      this.$store
        .dispatch("global/create", {
          payload: {
            id_user: this.loggedUser.id,
            id_story: this.currentStory.id,
            type_file: 0,
            message: this.commentMsg
          },
          route: "/chat_message/save",
          module: null
        })
        .then((response) => {
          this.commentLoading = false
          notify().then(({ notification }) => {
            notification(
              "comment-story",
              response.code,
              this.$snotify,
              this.$i18n.locale
            )
          })

          this.closeCommentModal()
        })
        .catch((error) => {
          console.log("story like error:", error)
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    closeCommentModal() {
      this.toggleTimer("resume")
      this.commentMsg = ""
      this.commentLoading = false
      this.commentModal = false
    },
    onImageLoad() {
      if (this.$refs["story-loader"])
        this.$refs["story-loader"].classList.add("hidden")
      if (
        this.$refs["story-" + this.currentStory.id] &&
        this.$refs["story-" + this.currentStory.id].classList
      )
        this.$refs["story-" + this.currentStory.id].classList.remove("hidden")
      if (
        this.$refs["story-" + this.currentStory.id][0] &&
        this.$refs["story-" + this.currentStory.id][0].classList
      )
        this.$refs["story-" + this.currentStory.id][0].classList.remove(
          "hidden"
        )
      this.startTimer()
      this.$socket.emit("story_viewed", {
        id_user: this.loggedUser.id,
        id_story: this.currentStory.id
      })
    },
    showImageLoader() {
      if (this.$refs["story-loader"]) {
        if (this.$refs["story-loader"].classList.has("hidden"))
          this.$refs["story-loader"].classList.remove("hidden")
      }

      if (
        this.$refs["story-" + this.currentStory.id] &&
        this.$refs["story-" + this.currentStory.id].classList &&
        !this.$refs["story-" + this.currentStory.id].classList.has("hidden")
      )
        this.$refs["story-" + this.currentStory.id].classList.add("hidden")
      if (
        this.$refs["story-" + this.currentStory.id][0] &&
        this.$refs["story-" + this.currentStory.id][0].classList &&
        !this.$refs["story-" + this.currentStory.id][0].classList.has("hidden")
      )
        this.$refs["story-" + this.currentStory.id][0].classList.add("hidden")
    },
    startTimer() {
      this.time = this.storyDuration
      window.vueComponent = this
      this.intervalID = setInterval(function () {
        window.vueComponent.time -= 50
      }, 50)
    },
    toggleTimer(action) {
      if (action === "pause") {
        this.resetInterval()
      } else if (action === "resume") {
        this.intervalID = setInterval(function () {
          window.vueComponent.time -= 50
        }, 50)
      }
    },
    changeStory(direction, byInput) {
      let success = false
      this.storyDuration = DEFAULT_STORY_DURATION
      this.wasPaused = false

      if (direction === "forward") {
        if (
          this.stories.length > 0 &&
          this.stories[this.currentStoryIndex + 1]
        ) {
          this.currentStoryIndex += 1
          this.currentStory = this.stories[this.currentStoryIndex]
          success = true
        } else {
          if (!this.allowNextStory) return this.closeModal()
          this.hasNeighbouringUser(direction)
        }
      }
      if (direction === "backwards") {
        if (this.stories[this.currentStoryIndex - 1]) {
          this.currentStoryIndex -= 1
          this.currentStory = this.stories[this.currentStoryIndex]
          success = true
        } else {
          this.hasNeighbouringUser(direction)
        }
      }
      if (byInput && success) {
        this.resetInterval()
      }
      const allow = this.stories[this.currentStoryIndex + 1] ? true : false
      this.checkAllowNextStory(allow)
    },
    checkAllowNextStory(allow) {
      if (!this.userView && allow) return (this.allowNextStory = true)
      if (this.userView && !allow) return (this.allowNextStory = false)

      return (this.allowNextStory = true)
    },
    hasNeighbouringUser(direction) {
      const index = this.userStories.findIndex(
        (x) => x.id === this.storiesUser.id
      )
      if (index === -1) {
        // If user deleted all self stories
        this.resetInterval()
        if (this.userStories[0])
          this.$store.dispatch("story/setItem", this.userStories[0])
        else this.closeModal()
      } else {
        const newIndex = direction === "forward" ? index + 1 : index - 1
        if (this.userStories[newIndex]) {
          this.resetInterval()
          this.$store.dispatch("story/setItem", this.userStories[newIndex])
        } else {
          this.closeModal()
        }
      }
    },
    resetInterval() {
      clearInterval(this.intervalID || window.vueComponent.intervalID)
      this.intervalID = null
    },
    setCurrentStory() {
      if (this.storiesUser.stories_viewed === 0) {
        let index
        index = this.stories.findIndex((x) => x.viewed === 0)
        // If there is a story not viewed yet, set it to current
        if (index !== -1) {
          this.currentStoryIndex = index
          this.currentStory = this.stories[index]
        } else {
          this.currentStoryIndex = 0
          this.currentStory = this.stories[0]
        }
      } else {
        // If every story was viewed, just set to first to start again
        this.currentStoryIndex = 0
        this.currentStory = this.stories[0]
      }
    },
    handleKeyUp(event) {
      if (event.key === "ArrowRight")
        window.vueComponent.changeStory("forward", true)
      if (event.key === "ArrowLeft")
        window.vueComponent.changeStory("backwards", true)
    },
    updateProgress(event) {
      window.vueComponent.time =
        (event.target.duration - event.target.currentTime) * 1000
    },
    onVideoPause(event) {
      window.vueComponent.wasPaused = true
    },
    onVideoPlay(event) {
      this.$socket.emit("story_viewed", {
        id_user: this.loggedUser.id,
        id_story: this.currentStory.id
      })
      // Only set variables on first play
      if (!this.wasPaused) {
        this.storyDuration = event.target.duration * 1000
        this.time = this.storyDuration
        window.vueComponent = this
      }
    }
  },
  watch: {
    time() {
      // Set current story to next if it exists
      if (this.currentStory.type === 1) {
        if (this.time && this.time <= 0 && this.intervalID) {
          this.resetInterval()
          this.changeStory("forward")
        }
      } else {
        if (this.time <= 0) {
          this.changeStory("forward")
        }
      }
    },
    stories: {
      handler() {
        if (this.stories.length > 0 && !this.intervalID) {
          // Set story only if there is no story playing
          this.setCurrentStory()
        }
      },
      deep: true
    },
    show() {
      if (!this.show) {
        if (this.intervalID) this.resetInterval()
        this.currentStory = {}
        this.currentStoryIndex = null
        this.time = null
        this.allowNextStory = true
        this.wasPaused = false
        window.removeEventListener("keyup", this.handleKeyUp)
      } else {
        this.wasPaused = false
        this.allowNextStory = true
        if (this.stories.length > 0 && !this.currentStory.id)
          this.setCurrentStory()
        window.addEventListener("keyup", this.handleKeyUp)
      }
    }
  },
  beforeDestroy() {
    if (this.intervalID) this.resetInterval()
    window.removeEventListener("keyup", this.handleKeyUp)
  },
  beforeUnmount() {
    if (this.intervalID) this.resetInterval()
    window.removeEventListener("keyup", this.handleKeyUp)
  }
}
</script>

<style lang="scss">
.story-nav {
  @apply bg-white p-2 rounded-lg shadow-xl cursor-pointer;
  border: 1px solid #a0a0a0;
  z-index: 10;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);

  &.left {
    left: -10%;
  }

  &.right {
    right: -10%;
  }
}

@media (max-width: 768px) {
  .story-nav {
    &.left {
      left: 1%;
    }

    &.right {
      right: 1%;
    }
  }
}
</style>
