<template>
  <div v-if=loggedUser>
    <div class="flex bg-white dark:bg-jevi-dark">
      <img v-if="isLoading" class="w-1/2 md:w-1/6 main-loader" :src="
        theme === 'dark'
          ? '/static/loading_darkmode.gif'
          : '/static/loading_lightmode.gif'
      " alt="loading-gif" />
      <div class="content-layout ml-0 lg:ml-60">
        <navbar-menu @collapse="collapseExpand" />
        <vertical-nav-menu :menuExpanded="menuExpanded" @closeMenu="collapseExpand" :newPostModal.sync="newPostModal" />

        <br />
        <router-view :style="
          activeRadio &&
            currentRadio &&
            (resumablePostVideo || resumableStoryVideo)
            ? 'margin-bottom: 7.5rem'
            : activeRadio &&
              currentRadio &&
              !resumablePostVideo &&
              !resumableStoryVideo
              ? 'margin-bottom: 4.5rem'
              : !activeRadio &&
                !currentRadio &&
                (resumablePostVideo || resumableStoryVideo)
                ? 'margin-bottom: 3rem'
                : ''
        " class="main-view" :class="isLoading ? 'opacity-0' : ''" @finishLoading="finishLoading"
          @startLoading="startLoading" :postModal.sync="postModal" :storyCreationModal.sync="storyCreationModal"
          :currentPost.sync="currentPost" :activeRadio.sync="activeRadio" @openPostReport="openPostReport"
          @openStory="openStory" @sharePost="triggerSharePost" />
      </div>
    </div>

    <div v-if="resumablePostVideo || resumableStoryVideo" class="radio-player" :style="
      theme === 'dark'
        ? 'background-color: #171821'
        : 'background-color: #FAFAFA'
    ">
      <div class="flex justify-between items-center h-full">
        <div class="flex">
          <div style="width: 4rem"></div>

          <vue-ellipse-progress class="my-auto" :progress="
            resumablePostVideo
              ? resumablePostVideo.progress
              : resumableStoryVideo.progress
          " color="#ffa500" emptyColor="#734a00" :legend="false" :size="20" reverse />

          <span class="ml-3 text-black dark:text-white text-sm">
            {{ $i18n.t("uploading_video") }}:
          </span>
          <span class="ml-1 text-black dark:text-white text-sm" v-if="resumablePostVideo">
            {{
                resumableVideo.text.length > 50
                  ? resumableVideo.text.slice(0, 50) + "..."
                  : resumableVideo.text
            }}
          </span>
          <span class="ml-1 text-black dark:text-white text-sm" v-if="resumableStoryVideo">
            {{
                resumableStoryVideo.text.length > 50
                  ? resumableStoryVideo.text.slice(0, 50) + "..."
                  : resumableStoryVideo.text
            }}
          </span>
        </div>
        <div class="flex items-center">
          <img src="/static/icons/cancel.svg" alt="Cancel icon" class="mr-2 mx my-auto dark:filter invert"
            style="cursor: pointer; width: 30px" @click="cancelUpload(resumablePostVideo ? 'video' : 'story')" />
        </div>
      </div>
    </div>

    <div v-if="activeRadio && currentRadio" class="radio-player" :style="
      theme === 'dark'
        ? 'background-color: #171821'
        : 'background-color: #FAFAFA'
    ">
      <div class="flex justify-between items-center h-full">
        <icon v-show="audioStatus === 'paused'" class="text-black dark:text-white text-2xl ml-3 sm:ml-10" icon="play"
          style="cursor: pointer" @click="toggleAudio('play')" />
        <icon v-show="audioStatus === 'playing'" class="dark:text-white text-2xl ml-3 sm:ml-10" icon="pause"
          style="cursor: pointer" @click="toggleAudio('pause')" />
        <div class="flex justify-start">
          <img :src="currentRadio.logo" alt="Logo de la radio" class="object-cover mx-3 hidden sm:block"
            style="aspect-ratio: 16/9; height: 3.5rem" />
          <div class="mx-3">
            <p class="text-black dark:text-white text-sm md:text-md font-bold">
              {{ currentRadio.name }}
            </p>
            <p class="text-black dark:text-white text-sm">
              {{
                  currentRadio.description.length > 50
                    ? currentRadio.description.slice(0, 50) + "..."
                    : currentRadio.description
              }}
            </p>
          </div>
        </div>
        <div class="flex items-center">
          <img class="sidebar-image active" src="/static/icons/messages.svg" alt="station-chat"
            style="cursor: pointer; width: 25px" @click="stationChat()" />

          <div class="volume my-auto">
            <div class="slider slider--volume volume_slider my-auto">
              <input type="range" v-model="radioVolume" min="0" max="100" />
            </div>
            <icon v-if="radioVolume > 50" class="dark:text-white text-2xl ml-2 my-auto" icon="volume-up" />
            <icon v-else-if="radioVolume > 0" class="dark:text-white text-2xl ml-2 my-auto" icon="volume-down" />
            <icon v-else class="dark:text-white text-2xl ml-2 my-auto" icon="volume-mute" />
          </div>

          <img src="/static/icons/cancel.svg" alt="Cancel icon" class="mr-2 mx my-auto dark:filter invert"
            style="cursor: pointer; width: 30px" @click="resetRadio" />
        </div>

        <audio :src="currentRadio.link" @error="triggerAudioError" class="hidden" ref="audio-element"></audio>
      </div>
    </div>

    <t-modal :header="$i18n.t('new_post')" :value="newPostModal" @closed="closeModal('newPostModal')"
      :clickToClose="false" :escToClose="false" variant="3xl">
      <div class="p-3 sm:px-8 sm:py-3" v-show="postType === 3">
        <t-input-group :feedback="
          errors.first('description') ? errors.first('description') : ''
        " :variant="errors.first('description') ? 'danger' : ''" class="my-3 relative">
          <Suggestions v-if="postType === 3" :text.sync="postDescription" :inputId="'thought-textarea'"
            :taggedUsers.sync="taggedUsers" direction="bottom" />
          <t-textarea v-validate="'required|min:1|max:200'" data-vv-validate-on="input" id="thought-textarea"
            name="description" v-model="postDescription" :placeholder="$i18n.t('what_are_you_thinking')">
          </t-textarea>
        </t-input-group>

        <t-input-group v-if="loggedUser.id_role === 1" :feedback="
          errors.first('id_country') ? errors.first('id_country') : ''
        " :variant="errors.first('id_country') ? 'danger' : ''" class="mb-2">
          <t-rich-select v-model="id_country" name="id_country" :placeholder="$i18n.t('select_a_country')"
            :disabled="isLoading" noResultsText="No results"
            :variant="errors.first('id_country') ? 'danger' : 'dynamic'" :options="countries"
            :textAttribute="$i18n.locale + '_name'" />
        </t-input-group>

        <div class="flex justify-end mb-5">
          <t-button type="button" @click="sendPost" :disabled="newPostLoading">
            <span v-if="newPostLoading">
              <t-loader extraClass="border-orange-primary" />
            </span>
            <span v-else class="text-white">{{ $i18n.t("publish") }}</span>
          </t-button>
        </div>

        <div class="flex justify-around flex-wrap items-center">
          <div
            class="dark:bg-gray-600 p-2 sm:p-5 shadow-2xl flex items-center justify-center my-1 rounded-md border-gray-400 dark:border-white"
            style="
              width: 100px;
              height: 100px;
              cursor: pointer;
              border-width: 1px;
            " @click="postType = 1">
            <div>
              <img src="/static/icons/picture_icon.svg" class="dark:filter invert w-4/5 mx-auto" alt="Add image icon" />
              <p class="text-center text-lg dark:text-white">
                {{ $i18n.t("picture") }}
              </p>
            </div>
          </div>

          <div
            class="dark:bg-gray-600 p-2 sm:p-5 shadow-2xl flex items-center justify-center my-1 rounded-md border-gray-400 dark:border-white"
            style="
              width: 100px;
              height: 100px;
              cursor: pointer;
              border-width: 1px;
            " @click="postType = 2">
            <div>
              <img src="/static/icons/video_icon.svg" class="dark:filter invert w-4/5 mx-auto" alt="Add video icon" />
              <p class="text-center text-lg dark:text-white">
                {{ $i18n.t("video") }}
              </p>
            </div>
          </div>

          <div
            class="dark:bg-gray-600 p-2 sm:p-5 shadow-2xl flex items-center justify-center my-1 rounded-md border-gray-400 dark:border-white"
            style="
              width: 100px;
              height: 100px;
              cursor: pointer;
              border-width: 1px;
            " @click="postType = 4">
            <div>
              <img src="/static/icons/poll_icon.svg" class="dark:filter invert w-4/5 mx-auto" alt="Add video icon" />
              <p class="text-center text-lg dark:text-white">
                {{ $i18n.t("poll") }}
              </p>
            </div>
          </div>

          <div
            class="dark:bg-gray-600 p-2 sm:p-5 shadow-2xl flex items-center justify-center my-1 rounded-md border-gray-400 dark:border-white"
            style="
              width: 100px;
              height: 100px;
              cursor: pointer;
              border-width: 1px;
            " @click="openStoryInstead">
            <div>
              <img src="/static/icons/moment.svg" class="dark:filter invert w-4/5 mx-auto" alt="Add story icon" />
              <p class="text-center text-lg dark:text-white">
                {{ $i18n.t("story") }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="p-3 sm:px-8 sm:py-3" v-show="postType === 1">
        <t-button type="button" @click="returnCreateModal" class="mb-3">
          <icon class="mr-2" icon="angle-left" />{{
              $i18n.t("return")
          }}
        </t-button>

        <t-upload-file v-show="!postPictureB64" class="sm:w-2/5 mx-auto" :file.sync="postPicture"
          :base64.sync="postPictureB64" :loading.sync="isLoading" ref="postPicture" />
        <cropper class="cropper" :src="postPictureB64" :stencil-props="{
          aspectRatio: 1
        }" @change="(data) => cropperChange(data, 'postPicture')" />

        <t-input-group :feedback="
          errors.first('description') ? errors.first('description') : ''
        " :variant="errors.first('description') ? 'danger' : ''" class="relative"
          :class="loggedUser.id_role === 1 ? 'mt-8 mb-4' : 'my-8'">
          <Suggestions v-if="postType === 1" :text.sync="postDescription" :inputId="'picture-textarea'"
            :taggedUsers.sync="taggedUsers" />
          <t-textarea v-validate="'required|min:1|max:1000'" id="picture-textarea" data-vv-validate-on="input"
            v-model="postDescription" :placeholder="$i18n.t('description')">
          </t-textarea>
        </t-input-group>

        <t-input-group v-if="loggedUser.id_role === 1" :feedback="
          errors.first('id_country') ? errors.first('id_country') : ''
        " :variant="errors.first('id_country') ? 'danger' : ''" class="mb-2">
          <t-rich-select v-model="id_country" name="id_country" :placeholder="$i18n.t('select_a_country')"
            :disabled="isLoading" noResultsText="No results"
            :variant="errors.first('id_country') ? 'danger' : 'dynamic'" :options="countries"
            :textAttribute="$i18n.locale + '_name'" />
        </t-input-group>
      </div>

      <div class="p-3 sm:px-8 sm:py-3" v-show="postType === 2">
        <t-button type="button" @click="returnCreateModal" class="mb-3">
          <icon class="mr-2" icon="angle-left" />{{
              $i18n.t("return")
          }}
        </t-button>
        <template v-if="!videoType">
          <div class="flex justify-around flex-wrap items-center">
            <!--   <div
              class="dark:bg-gray-600 p-2 sm:p-5 shadow-2xl flex items-center justify-center my-1 rounded-md border-gray-400 dark:border-white"
              style="
                width: 100px;
                height: 100px;
                cursor: pointer;
                border-width: 1px;
              "
              @click="videoType = 'gallery'"
            >
              <div>
                <img
                  src="/static/icons/picture_icon.svg"
                  class="dark:filter invert w-4/5 mx-auto"
                  alt="Gallery icon"
                />
                <p class="text-center text-lg dark:text-white">
                  {{ $i18n.t("gallery") }}
                </p>
              </div>
            </div> -->
            <div
              class="dark:bg-gray-600 p-2 sm:p-5 shadow-2xl flex items-center justify-center my-1 rounded-md border-gray-400 dark:border-white"
              style="
                width: 100px;
                height: 100px;
                cursor: pointer;
                border-width: 1px;
              " @click="videoType = 'youtube'">
              <div>
                <img src="/static/icons/picture_icon.svg" class="dark:filter invert w-4/5 mx-auto"
                  alt="Add image icon" />
                <p class="text-center text-lg dark:text-white">Youtube</p>
              </div>
            </div>
          </div>
        </template>
        <!-- Gallery video -->
        <template v-else-if="videoType === 'gallery'">
          <t-upload-file class="sm:w-2/5 mx-auto" :file.sync="galleryVideo" :loading.sync="isLoading"
            :aspectRatio.sync="videoAspectRatio" :type="'video'" ref="galleryVideo" />
        </template>
        <!-- Youtube video -->
        <template v-else-if="videoType === 'youtube'">
          <t-input-group :feedback="
            errors.first('postVideo') ? errors.first('postVideo') : ''
          " :variant="errors.first('postVideo') ? 'danger' : ''" class="my-3">
            <t-input v-validate="
              postType === 2 ? 'required|youtube_url' : 'youtube_url'
            " data-vv-validate-on="input" name="postVideo" v-model="postVideo" :placeholder="$i18n.t('video_url')"
              @input="processPostVideoChange">
            </t-input>
          </t-input-group>
          <iframe v-if="embedUrl" id="youtubeEmbed" width="50%" height="auto" class="mx-auto px-2 py-1" :src="embedUrl"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>

          <t-button class="mx-auto" :disabled="postVideo.length === 0" type="button" @click="validateVideo">{{
              $i18n.t("validate_video")
          }}</t-button>
        </template>

        <t-input-group :feedback="
          errors.first('description') ? errors.first('description') : ''
        " :variant="errors.first('description') ? 'danger' : ''" class="my-3 relative">
          <Suggestions v-if="postType === 2" :text.sync="postDescription" :inputId="'video-textarea'"
            :taggedUsers.sync="taggedUsers" />
          <t-textarea v-validate="'required|min:1|max:1000'" data-vv-validate-on="input" id="video-textarea"
            v-model="postDescription" :placeholder="$i18n.t('description')">
          </t-textarea>
        </t-input-group>

        <t-input-group v-if="loggedUser.id_role === 1" :feedback="
          errors.first('id_country') ? errors.first('id_country') : ''
        " :variant="errors.first('id_country') ? 'danger' : ''" class="mb-2">
          <t-rich-select v-model="id_country" name="id_country" :placeholder="$i18n.t('select_a_country')"
            :disabled="isLoading" noResultsText="No results"
            :variant="errors.first('id_country') ? 'danger' : 'dynamic'" :options="countries"
            :textAttribute="$i18n.locale + '_name'" />
        </t-input-group>
      </div>

      <div class="p-3 sm:px-8 sm:py-3" v-show="postType === 4">
        <t-button type="button" @click="returnCreateModal" class="mb-3">
          <icon class="mr-2" icon="angle-left" />{{
              $i18n.t("return")
          }}
        </t-button>

        <p class="text-md dark:text-white mb-1">
          {{ $i18n.t("pictures") }} {{ $i18n.t("optional") }}
        </p>
        <div class="flex justify-around flex-wrap items-center mb-3 mt-2">
          <div class="flex flex-col align-items-center sm:w-1/5 mx-auto my-1 sm:my-0">
            <t-upload-file :key="refresh1" style="aspect-ratio: 1" :file.sync="poll.url_picture1" :loading.sync="isLoading" />

            <div class="flex w-full mt-2 justify-center">
              <t-button @click="removePicture(1)" class="w-fit" :disabled="!poll.url_picture1" :class="!poll.url_picture1 ? 'cursor-default' : ''">
                <img src="/static/icons/delete.svg" class="dark:filter invert" alt="Delete icon"
                  width="24px" height="24px" />
              </t-button>
            </div>
          </div>

          <div class="flex flex-col align-items-center sm:w-1/5 mx-auto my-1 sm:my-0">
            <t-upload-file :key="refresh2" style="aspect-ratio: 1" :file.sync="poll.url_picture2" :loading.sync="isLoading" />

            <div class="flex w-full mt-2 justify-center">
              <t-button @click="removePicture(2)" class="w-fit" :disabled="!poll.url_picture2" :class="!poll.url_picture2 ? 'cursor-default' : ''">
                <img src="/static/icons/delete.svg" class="dark:filter invert" alt="Delete icon"
                  width="24px" height="24px" />
              </t-button>
            </div>
          </div>

          <div class="flex flex-col align-items-center sm:w-1/5 mx-auto my-1 sm:my-0">
            <t-upload-file :key="refresh3" style="aspect-ratio: 1" :file.sync="poll.url_picture3" :loading.sync="isLoading" />

            <div class="flex w-full mt-2 justify-center">
              <t-button @click="removePicture(3)" class="w-fit" :disabled="!poll.url_picture3" :class="!poll.url_picture3 ? 'cursor-default' : ''">
                <img src="/static/icons/delete.svg" class="dark:filter invert" alt="Delete icon"
                  width="24px" height="24px" />
              </t-button>
            </div>
          </div>

          <div class="flex flex-col align-items-center sm:w-1/5 mx-auto my-1 sm:my-0">
            <t-upload-file :key="refresh4" style="aspect-ratio: 1" :file.sync="poll.url_picture4" :loading.sync="isLoading" />

            <div class="flex w-full mt-2 justify-center">
              <t-button @click="removePicture(4)" class="w-fit" :disabled="!poll.url_picture4" :class="!poll.url_picture4 ? 'cursor-default' : ''">
                <img src="/static/icons/delete.svg" class="dark:filter invert" alt="Delete icon"
                  width="24px" height="24px" />
              </t-button>
            </div>
          </div>
        </div>

        <!-- <p
          class="text-black dark:text-white text-sm sm:text-md"
          style="cursor: pointer"
        >
          <icon
            style="width: 26px; height: 26px; cursor: pointer"
            icon="plus"
            class="rounded-full bg-primary-700 text-white text-3xl p-1"
          />

          {{ $i18n.t("add_question") }}
        </p> -->

        <t-input-group v-if="loggedUser.id_role === 1" :feedback="
          errors.first('id_country') ? errors.first('id_country') : ''
        " :variant="errors.first('id_country') ? 'danger' : ''" class="mb-2">
          <t-rich-select v-model="id_country" name="id_country" :placeholder="$i18n.t('select_a_country')"
            :disabled="isLoading" noResultsText="No results"
            :variant="errors.first('id_country') ? 'danger' : 'dynamic'" :options="countries"
            :textAttribute="$i18n.locale + '_name'" />
        </t-input-group>

        <div v-for="(element, questionIndex) in poll.questions" :key="element.key">
          <div class="flex">
            <div class="w-full">
              <t-input-group :feedback="
                errors.first('question') ? errors.first('question') : ''
              " :variant="errors.first('question') ? 'danger' : ''" class="my-1">
                <t-input v-validate="postType === 4 ? 'simple_required' : ''" data-vv-validate-on="input"
                  name="question" v-model="element.question" :placeholder="$i18n.t('question')">
                </t-input>
              </t-input-group>
            </div>
          </div>
          <div class="w-11/12" v-for="(answerElement, answerIndex) in element.answers" :key="answerElement.key">
            <t-input-group :feedback="
              errors.first(answerElement.key)
                ? errors.first(answerElement.key)
                : ''
            " :variant="errors.first(answerElement.key) ? 'danger' : ''" class="my-2 ml-5">
              <t-input v-validate="
                postType === 4 && [0, 1].includes(answerIndex)
                  ? 'simple_required|simple_max:30'
                  : 'simple_max:30'
              " data-vv-validate-on="input" :name="answerElement.key" v-model="answerElement.answer"
                :placeholder="$i18n.t('answer') + ' #' + (answerIndex + 1)" @input="
                  addAnswerToQuestion(
                    questionIndex,
                    answerIndex,
                    answerElement.answer
                  )
                ">
              </t-input>
            </t-input-group>
          </div>
        </div>
      </div>

      <div class="flex justify-center" v-show="postType === 1 || postType === 4">
        <t-button type="button" @click="sendPost" :disabled="newPostLoading">
          <span v-if="newPostLoading">
            <t-loader extraClass="border-orange-primary" />
          </span>
          <span v-else class="text-white">{{ $i18n.t("publish") }}</span>
        </t-button>
      </div>

      <div class="flex justify-center" v-show="postType === 2">
        <t-button type="button" @click="sendPost" :disabled="
          (videoType === 'youtube' && postVideoThumbnail === '') ||
          (videoType === 'gallery' && !galleryVideo) ||
          newPostLoading
        ">
          <span v-if="newPostLoading">
            <t-loader extraClass="border-orange-primary" />
          </span>
          <span v-else class="text-white">{{ $i18n.t("publish") }}</span>
        </t-button>
      </div>
    </t-modal>

    <t-modal header="" :value="postModal" @closed="closeModal('postModal')" variant="post">
      <div class="block md:flex">
        <div class="mx-auto w-full md:w-3/5">
          <div style="background-color: transparent; ">
            <template>


              <div>
                <PostType :element="currentPost" :sizePx="-1" :loggedUserID="loggedUser.id" :atModal="true" />

                <ActionBar :element="currentPost" :allowLike="true" :allowComments="false" :allowSubscription="true"
                  :OAuthPreferences="OAuthPreferences" :isLoadingSubscription="isLoadingSubscription"
                  :calculateHighNumber="calculateHighNumber" @likePost="likePost" @openModal="openLikesModal"
                  @sharePost="$emit('sharePost', currentPost)" @subscribeChannel="subscribeChannel(currentPost)"
                  :id="loggedUser.id" @openProfile="openProfileFromLikesModal" />

                <PostText :element="currentPost" />
              </div>
            </template>
          </div>
        </div>

        <div class="w-full md:w-2/5 flex flex-col content-between px-2 mt-2">
          <comments @openProfile="openProfile" :post="currentPost" :showNewComment="true" :height="'85vh'"
            @commentDeleted="currentPost.total_comments--" />
        </div>
      </div>
    </t-modal>

    <t-modal header="" :value="searchModal" @closed="closeModal('searchModal')" variant="3xl">
      <div class="w-full px-2">
        <searchbar :placeholder="$i18n.t('search')" :autofocus="true" @changeQuery="changeQuery"
          @loading="searchbarLoading" @blur="updateSearchQuery" />

        <div class="flex flex-col xs:flex-row xs:justify-center xs:items-center my-5">
          <t-button variant="hashtag" class="mx-1 my-1 xs:my-0" :class="searchType === 'user' ? 'active-hashtag' : ''"
            @click="searchType = 'user'">{{ $i18n.t("users") }}</t-button>
          <t-button variant="hashtag" class="mx-1 my-1 xs:my-0" :class="searchType === 'radio' ? 'active-hashtag' : ''"
            @click="searchType = 'radio'">{{ $i18n.t("radio") }}</t-button>
          <t-button variant="hashtag" class="mx-1 my-1 xs:my-0"
            :class="searchType === 'hashtag' ? 'active-hashtag' : ''" @click="searchType = 'hashtag'">Hashtags
          </t-button>
        </div>
      </div>

      <div class="block px-2" style="height: 60vh; overflow-y: auto">
        <template v-if="isSearching && searchResults.length === 0">
          <div class="w-full flex items-center justify-center">
            <img class="w-1/2 md:w-1/6 mx-auto" :src="
              theme === 'dark'
                ? '/static/loading_darkmode.gif'
                : '/static/loading_lightmode.gif'
            " alt="loading-gif" style="z-index: 50" />
          </div>
        </template>
        <template v-else-if="searchTriggered && searchResults.length === 0">
          <div class="flex justify-center items-center">
            <p class="text-black dark:text-white">
              {{ $i18n.t("no_results") }}
            </p>
          </div>
        </template>
        <template v-else>
          <template v-if="searchType === 'user'">
            <div v-if="!searchTriggered && searchResults.length === 0">
              <p class="text-black dark:text-white text-xl text-center">
                {{ $i18n.t("user_top_10") }}
              </p>
              <div class="my-1 w-full" v-for="user in user_top10" :key="'top10-user-' + user.id">
                <img class="object-cover mx-auto" style="max-height: 200px; aspect-ratio: 1"
                  src="/static/logo_square.png" :ref="'top-10-loading-' + user.id" />
                <img class="hidden w-full object-cover z-20" style="max-height: 200px; cursor: pointer" :src="
                  user.header_picture
                    ? user.header_picture
                    : '/static/banner-placeholder.jpg'
                " alt="Profile header" @click="openProfile(user)" @load="
  onImageLoad('top-10-loading-', 'top-10-picture-', user.id)
" :ref="'top-10-picture-' + user.id" />

                <profile-card :user="user" :canEdit="false" :allowFollow="true" :showFollowModals="false"
                  @click="openProfile(user)" @userUpdate="updateTop10User" :clickToProfile="true"
                  style="cursor: pointer" />
              </div>
            </div>
            <div class="my-1 w-full" v-for="user in searchResults" :key="'result-user-' + user.id">
              <div class="w-full flex justify-between items-center">
                <div class="flex w-full">
                  <div class="w-16 h-16" style="cursor: pointer" @click="openProfile(user)">
                    <profile-picture :data="user" :sizePx="128" />
                  </div>
                  <div class="ml-2" style="cursor: pointer" @click="openProfile(user)">
                    <p class="text-black dark:text-white text-sm" style="display: flex; align-items: center">
                      {{ user.username }}
                      <span class="ml-1" v-if="user.verified == 1">
                        <img src="/static/user_verified.png" style="
                             {
                              display: flex;
                              height: 16px;
                              width: 16px;
                            }
                          " />
                        <!--<font-awesome-icon class="mt-2 mb-0 my-0" icon="check-circle" style="color: #6a71ec; font-size: 16px" />-->
                      </span>
                    </p>
                    <p class="text-black dark:text-white text-sm">
                      {{ `${user.first_name} ${user.last_name}` }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-if="searchType === 'radio'">
            <div v-if="!searchTriggered && searchResults.length === 0">
              <p class="text-black dark:text-white text-xl text-center">
                {{ $i18n.t("radio_top_5") }}
              </p>
              <div class="my-1 w-full" v-for="data in radio_top5" :key="'top5-radio-' + data.id">
                <div class="w-full flex justify-between items-center">
                  <div class="flex w-full">
                    <div class="w-16 h-16" style="cursor: pointer">
                      <station-cover :station="data" :size="64" extraClass="rounded-full"
                        @click.native="playRadio(data)" />
                    </div>
                    <div class="ml-2" style="cursor: pointer" @click="playRadio(data)">
                      <p class="text-black dark:text-white text-sm">
                        {{ data.name }}
                      </p>
                      <p class="text-black dark:text-white text-sm">
                        {{
                            data.description.length > 50
                              ? data.description.slice(0, 50) + "..."
                              : data.description
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="my-1 w-full" v-for="data in searchResults" :key="'search-radio-' + data.id">
              <div class="w-full flex justify-between items-center">
                <div class="flex w-full">
                  <div class="w-16 h-16" style="cursor: pointer">
                    <station-cover :station="data" :size="64" extraClass="rounded-full"
                      @click.native="playRadio(data)" />
                  </div>
                  <div class="ml-2" style="cursor: pointer">
                    <p class="text-black dark:text-white text-sm">
                      {{ data.name }}
                    </p>
                    <p class="text-black dark:text-white text-sm">
                      {{
                          data.description.length > 50
                            ? data.description.slice(0, 50) + "..."
                            : data.description
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-if="searchType === 'hashtag'">
            <div v-if="!searchTriggered && searchResults.length === 0">
              <p class="text-black dark:text-white text-xl text-center">
                {{ $i18n.t("hashtag_top_10") }}
              </p>
              <div class="my-1 w-full" v-for="data in hashtags_top10" :key="'top10-hashtag-' + data.id">
                <div class="flex w-full mb-5">
                  <div class="ml-2" style="cursor: pointer" @click="openPostsByHashtag(data)">
                    <p class="text-black dark:text-white text-sm">
                      {{ data.name }}
                    </p>
                    <p class="text-black dark:text-white text-sm">
                      {{ data.total_posts }}
                      {{
                          data.total_posts === 1
                            ? $i18n.t("post")
                            : $i18n.t("posts")
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="my-1 w-full" v-for="data in searchResults" :key="'search-hashtag-' + data.id">
              <div class="flex w-full mb-5">
                <div class="ml-2" style="cursor: pointer" @click="openPostsByHashtag(data)">
                  <p class="text-black dark:text-white text-sm">
                    {{ data.name }}
                  </p>
                  <p class="text-black dark:text-white text-sm">
                    {{ calculateHighNumber(data.total_posts) }}
                    {{
                        data.total_posts === 1
                          ? $i18n.t("post")
                          : $i18n.t("posts")
                    }}
                  </p>
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>
    </t-modal>
    <t-modal header="" :value="postReportModal" @closed="closeModal('postReportModal')" variant="lg">
      <div style="max-height: 50vh; overflow-y: auto">
        <div class="my-auto" v-if="postReportReasons.length === 0">
          <p class="text-black dark:text-white text-center">
            {{ $i18n.t("no_elements") }}
          </p>
        </div>
        <div class="my-1 w-full py-3" v-for="reason in postReportReasons" :key="'post-reason-' + reason.id"
          style="border-bottom: 1px solid #4a4a4a; cursor: pointer">
          <div class="flex my-3 flex-wrap flex-col md:flex-row">
            <label class="flex justify-between items-center mx-1 my-1">
              <span class="text-black dark:text-white text-sm">{{
                  reason[$i18n.locale + "_name"]
              }}</span>
            </label>
            <t-radio class="ml-auto mr-2 jevi-radio-input" :value="reason.id" v-model="reportReason"
              :disabled="isReportLoading" />
          </div>
        </div>
        <t-button @click="reportPost" class="mx-auto" style="height: fit-content"
          :disabled="!reportReason || isReportLoading">
          <span v-if="!isLoading" class="text-white">{{
              $i18n.t("report_post")
          }}</span>

          <span v-if="isReportLoading">
            <t-loader v-if="isReportLoading" extraClass="mx-auto my-auto border-orange-primary" />
          </span>
        </t-button>
      </div>
    </t-modal>

    <t-modal :header="$i18n.t('new_story')" :value="storyCreationModal" @closed="closeModal('storyCreationModal')"
      :clickToClose="false" :escToClose="false" variant="3xl">
      <div class="p-3 sm:px-8 sm:py-3">
        <div class="flex justify-around flex-wrap items-center">
          <template v-if="!galleryVideo">
            <t-upload-file v-show="!storyPictureB64" class="sm:w-2/5 mx-auto" :file.sync="storyPicture"
              :base64.sync="storyPictureB64" :loading.sync="isLoading" ref="postPicture" />
            <cropper ref="cropperStory" class="cropper story-cropper" :src="storyPictureB64" :aspectRatio="aspectRatio"
              :stencil-props="{
                aspectRatio
              }" @change="(data) => cropperChange(data, 'storyPicture')" />
          </template>
          <!--   <template v-if="!storyPicture">
            <t-upload-file
              class="sm:w-2/5 mx-auto"
              :file.sync="galleryVideo"
              :loading.sync="isLoading"
              :aspectRatio.sync="videoAspectRatio"
              :type="'video'"
              ref="galleryVideo"
            />
          </template> -->
        </div>

        <div class="flex justify-center flex-wrap items-center gap-2 mt-5" v-if="storyPictureB64">
          <t-button type="button" @click="changeAspectRatio('squared')"
            :variant="aspectRatio === 1 ? 'sm' : 'outline-gray-sm'">
            {{ $i18n.t("squared") }}
          </t-button>

          <t-button type="button" @click="changeAspectRatio('portrait')"
            :variant="aspectRatio === 9 / 16 ? 'sm' : 'outline-gray-sm'">
            {{ $i18n.t("portrait") }}
          </t-button>
        </div>

        <t-input-group :feedback="
          errors.first('storyDescription')
            ? errors.first('storyDescription')
            : ''
        " :variant="errors.first('storyDescription') ? 'danger' : ''" class="my-8">
          <t-textarea v-validate="'min:1|max:140'" data-vv-validate-on="input" name="storyDescription"
            v-model="storyText" :placeholder="$i18n.t('description')">
          </t-textarea>
        </t-input-group>
      </div>

      <div class="flex justify-center">
        <t-button type="button" @click="sendStory" :disabled="newStoryLoading || (!storyPicture && !galleryVideo)">
          <span v-if="newStoryLoading">
            <t-loader extraClass="border-orange-primary" />
          </span>
          <span v-else class="text-white">{{ $i18n.t("publish") }}</span>
        </t-button>
      </div>
    </t-modal>
    <t-modal :header="$i18n.t('share_post')" :value="shareModal" @closed="closeModal('shareModal')"
      :clickToClose="false" :escToClose="false" variant="lg">
      <div class="p-3 sm:px-8 sm:py-3">
        <div class="flex flex-col justify-center flex-wrap items-around" v-if="!shareType">
          <div v-if="
            (sharingPost.type === 2 || sharingPost.type === 5) &&
            sharingPost.user.id !== loggedUser.id
          " class="flex items-center gap-2 w-full cursor-pointer" @click="shareType = 3">
            <div class="dark:bg-gray-600 shadow-2xl my-1 rounded-md border-gray-400 dark:border-white" style="
                width: 35px;
                height: 35px;
                cursor: pointer;
                border-width: 1px;
              ">
              <img src="/static/icons/send.svg" class="dark:filter invert w-4/5 mx-auto my-auto h-full"
                alt="Send icon" />
            </div>

            <p class="text-center text-md dark:text-white">
              {{ $i18n.t("feed") }}
            </p>
          </div>
          <div class="flex items-center gap-2 w-full cursor-pointer" @click="shareType = 1">
            <div class="dark:bg-gray-600 shadow-2xl my-1 rounded-md border-gray-400 dark:border-white" style="
                width: 35px;
                height: 35px;

                border-width: 1px;
              ">
              <img src="/static/icons/profile.svg" class="dark:filter invert w-4/5 mx-auto my-auto h-full"
                alt="Profile icon" />
            </div>

            <p class="text-center text-md dark:text-white">
              {{ $i18n.t("friends") }}
            </p>
          </div>

          <div class="flex items-center gap-2 w-full cursor-pointer" @click="shareType = 2">
            <div class="dark:bg-gray-600 shadow-2xl my-1 rounded-md border-gray-400 dark:border-white" style="
                width: 35px;
                height: 35px;

                border-width: 1px;
              ">
              <img src="/static/icons/room.svg" class="dark:filter invert w-4/5 mx-auto" alt="Room icon" />
            </div>
            <p class="text-center text-md dark:text-white">
              {{ $i18n.t("communities") }}
            </p>
          </div>
        </div>
        <template v-if="shareType">
          <t-button type="button" @click="shareType = null" class="mb-3">
            <icon class="mr-2" icon="angle-left" />{{
                $i18n.t("return")
            }}
          </t-button>
        </template>
        <template v-if="shareType === 1">
          <div style="max-height: 50vh; overflow-y: auto">
            <div class="mx-auto text-center">
              <t-loader v-if="shareListLoading" extraClass="border-orange-primary" />
            </div>
            <div class="my-auto" v-if="chats.length === 0">
              <p class="text-black dark:text-white text-center">
                {{ $i18n.t("no_elements") }}
              </p>
            </div>
            <div class="my-1 w-full" v-for="element in chats" :key="'friend-' + element.id_chat">
              <div class="w-full flex justify-between items-center">
                <div class="flex w-2/3">
                  <div class="w-16 h-16">
                    <profile-picture :data="element.user" :sizePx="128" />
                  </div>
                  <div class="ml-2">
                    <p class="text-black dark:text-white text-sm" style="display: flex; align-items: center">
                      {{ element.user.username }}
                      <span class="ml-1" v-if="element.user.verified == 1">
                        <img src="/static/user_verified.png" style="
                             {
                              display: flex;
                              height: 16px;
                              width: 16px;
                            }
                          " />
                        <!--<font-awesome-icon class="mt-2 mb-0 my-0" icon="check-circle" style="color: #6a71ec; font-size: 16px" />-->
                      </span>
                    </p>
                    <p class="text-black dark:text-white text-sm">
                      {{
                          `${element.user.first_name} ${element.user.last_name}`
                      }}
                    </p>
                  </div>
                </div>
                <div class="w-1/3 my-auto text-center">
                  <t-radio class="jevi-radio-input" :value="element.id_chat" v-model="shareId"
                    :disabled="shareLoading" />
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-if="shareType === 2">
          <div style="max-height: 50vh; overflow-y: auto">
            <div class="mx-auto text-center">
              <t-loader v-if="shareListLoading" extraClass="border-orange-primary" />
            </div>
            <div class="my-auto" v-if="rooms.length === 0">
              <p class="text-black dark:text-white text-center">
                {{ $i18n.t("no_elements") }}
              </p>
            </div>
            <div class="my-1 w-full" v-for="room in rooms" :key="'community-' + room.id">
              <div class="w-full flex justify-between items-center">
                <div class="flex w-2/3">
                  <div class="w-16 h-16">
                    <profile-picture :data="room" :sizePx="128" />
                  </div>
                  <div class="ml-2 my-auto">
                    <p class="text-black dark:text-white text-sm">
                      {{ room.name }}
                    </p>
                  </div>
                </div>
                <div class="w-1/3 my-auto text-center">
                  <t-radio class="jevi-radio-input" :value="room.id" v-model="shareId" :disabled="shareLoading" />
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-if="shareType === 3">
          <div class="p-3 sm:px-8 sm:py-3">
            <p class="text-black dark:text-white text-md my-2">
              {{ $i18n.t("want_to_repost") }}
            </p>
            <template v-if="sharingPost && sharingPost.type === 5">
              <video class="mx-auto px-2 py-1" :src="sharingPost.imgkit_url_video || sharingPost.url_video"
                :style="sharingPost.aspect_ratio < 1 ? 'max-height: 45vh' : ''" playsinline controls autoplay loop muted
                controlsList="nodownload" />
            </template>
            <template v-if="sharingPost && sharingPost.type !== 5">
              <img :alt="sharingPost ? sharingPost.text : ''" class="mx-auto object-cover px-2 py-1"
                style="cursor: pointer" src="/static/logo_square.png"
                :ref="'loading-picture-repost-' + sharingPost.id" />
              <img :alt="sharingPost ? sharingPost.text : ''" class="hidden mx-auto object-cover px-2 py-1 w-3/5" @load="
                onRepostLoad(
                  'loading-picture-repost-',
                  'repost-',
                  sharingPost.id
                )
              " :src="
  sharingPost.imgkit_url_picture ||
  sharingPost.url_picture ||
  ''
" :ref="'repost-' + sharingPost.id" />
            </template>
          </div>
        </template>
      </div>

      <t-input-group v-if="[1, 2].includes(shareType)" class="my-5">
        <t-input name="newMessage" v-model="shareMsg" autocomplete="off" :placeholder="$i18n.t('send_a_message')">
        </t-input>
      </t-input-group>

      <t-input-group v-else-if="shareType === 3" :feedback="
        errors.first('repostDescription')
          ? errors.first('repostDescription')
          : ''
      " :variant="errors.first('repostDescription') ? 'danger' : ''" class="my-5 relative">
        <Suggestions v-if="sharingPost" :text.sync="postDescription" :inputId="'repost-textarea'"
          :taggedUsers.sync="taggedUsers" />
        <t-textarea v-validate="'simple_required|min:1|max:1000'" data-vv-validate-on="input" name="repostDescription"
          v-model="postDescription" :placeholder="$i18n.t('description')" id="repost-textarea">
        </t-textarea>
      </t-input-group>

      <div class="flex justify-center">
        <t-button v-if="shareType === 3" type="button" @click="sharePost" :disabled="shareLoading || !sharingPost.id">
          <span v-if="shareLoading">
            <t-loader extraClass="border-orange-primary" />
          </span>
          <span v-else class="text-white">{{ $i18n.t("share") }}</span>
        </t-button>
        <t-button v-else type="button" @click="sharePost" :disabled="shareLoading || !shareId || !sharingPost.id">
          <span v-if="shareLoading">
            <t-loader extraClass="border-orange-primary" />
          </span>
          <span v-else class="text-white">{{ $i18n.t("share") }}</span>
        </t-button>
      </div>
    </t-modal>

    <t-modal :header="$i18n.t('are_you_sure')" :value="deleteConfirmation" @closed="deleteConfirmation = false"
      variant="md">
      <div class="p-3">
        <p class="mb-1 text-black dark:text-white">
          {{ $i18n.t("video_cancel_confirmation") }}
        </p>
      </div>

      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button type="button" variant="gray" @click="deleteConfirmation = false" class="mr-2">
            {{ $i18n.t("no") }}
          </t-button>
          <t-button type="button" @click="cancelUpload(resumablePostVideo ? 'video' : 'story')">
            <span v-if="videoCancelLoading">
              <t-loader extraClass="border-orange-primary" />
            </span>
            <span v-else class="text-white">{{ $i18n.t("yes") }}</span>
          </t-button>
        </div>
      </template>
    </t-modal>
    <LikedByModal :modal="likesModal" :postID="postID" @closeModal="closeLikesModal"
      @openProfile="openProfileFromLikesModal" />

    <stories :show.sync="storyModal" @openProfile="openProfile" />
  </div>

  <div v-else>
    <div class="flex bg-white dark:bg-jevi-dark">
      <img v-if="isLoading" class="w-1/2 md:w-1/6 main-loader" :src="
        theme === 'dark'
          ? '/static/loading_darkmode.gif'
          : '/static/loading_lightmode.gif'
      " alt="loading-gif" />
      <div class="content-layout ml-0">
        <no-auth-navbar-menu @collapse="collapseExpand" />

        <br />
        <router-view class="main-view-noauth" :class="isLoading ? 'opacity-0' : ''" @finishLoading="finishLoading"
          @startLoading="startLoading" :postModal.sync="postModal" :storyCreationModal.sync="storyCreationModal"
          :currentPost.sync="currentPost" :activeRadio.sync="activeRadio" @openPostReport="openPostReport"
          @openStory="openStory" @sharePost="triggerSharePost" />
      </div>
    </div>

    <t-modal header="" :value="postModal" @closed="closeModal('postModal')" variant="post">
      <div class="block md:flex">
        <div class="mx-auto w-full md:w-3/5">
          <t-card style="background-color: transparent;">
            <template>


              <div>
                <PostType :element="currentPost" :sizePx="-1" :loggedUserID="loggedUser ? loggedUser.id : null" />

                <ActionBar :noAuth="true" :element="currentPost" :allowLike="true" :allowComments="false"
                  :allowSubscription="true" :OAuthPreferences="OAuthPreferences"
                  :isLoadingSubscription="isLoadingSubscription" :calculateHighNumber="calculateHighNumber"
                  @likePost="likePost" @openModal="openLikesModal" @sharePost="$emit('sharePost', currentPost)"
                  @subscribeChannel="subscribeChannel(currentPost)"
                  :id="loggedUser ? loggedUser.id : null" @openProfile="openProfileFromLikesModal" />

                <PostText :element="currentPost" />
              </div>
            </template>
          </t-card>
        </div>

        <div class="w-full md:w-2/5 flex flex-col content-between px-2 mt-2">
          <comments @openProfile="openProfile" :post="currentPost" :showNewComment="false" :height="'85vh'"
            @commentDeleted="currentPost.total_comments--" />
        </div>
      </div>
    </t-modal>
  </div>
</template>

<script>
import VerticalNavMenu from "../components/vertical-nav-menu/VerticalNavMenu"
import NavbarMenu from "../components/navbar/NavBar"
import NoAuthNavbarMenu from "../components/navbar/NoAuthNavBar"
import ProfilePicture from "@/components/user/ProfilePicture.vue"
import Comments from "@/components/post/comments"
import Searchbar from "@/components/Searchbar"
import ProfileCard from "@/components/user/ProfileCard.vue"
import StationCover from "@/components/radio/StationCover.vue"
import Stories from "@/components/story/Stories.vue"
import { Cropper } from "vue-advanced-cropper"
import Suggestions from "@/components/user/Suggestions.vue"
import Likes from "@/components/post/likes.vue"
import LikedByModal from "@/components/post/LikedByModal.vue"
import ActionBar from "@/components/post/ActionBar.vue"
import PostText from "@/components/post/PostText.vue"
import PostType from "@/components/post/PostType.vue"
import getFormData from "@/utils/convertToFormData"
import axios from "@/utils/axios.js"
import { VueEllipseProgress } from "vue-ellipse-progress"

function disableScrolling() {
  var x = window.scrollX
  var y = window.scrollY
  window.onscroll = function () {
    window.scrollTo(x, y)
  }
}

function enableScrolling() {
  window.onscroll = function () { }
}

const notify = () => import("@/utils/notify.js")
export default {
  data() {
    return {
      refresh1: 0,
      refresh2: 0,
      refresh3: 0,
      refresh4: 0,
      stopChecking: false,
      likesModal: false,
      postID: 0,
      deleteConfirmation: false,
      menuExpanded: false,
      titleName: null,
      isLoading: true,
      routeTitle: null,
      newPostModal: false,
      postPicture: null,
      storyPicture: null,
      postType: 3,
      videoType: null,
      videoAspectRatio: 0,
      postDescription: "",
      storyText: "",
      postVideo: "",
      galleryVideo: null,
      postVideoThumbnail: "",
      postModal: false,
      storyModal: false,
      storyCreationModal: false,
      currentPost: {
        comments: []
      },
      commentText: "",
      activeRadio: false,
      audioStatus: "paused",
      radioVolume: 100,
      embedUrl: null,
      newPostLoading: false,
      videoCancelLoading: false,
      newStoryLoading: false,
      searchTriggered: false,
      searchQuery: "",
      searchType: "user",
      searchResults: [],
      user_top10: [],
      radio_top5: [],
      hashtags_top10: [],
      aspectRatio: 9 / 16,
      originalAspectRatio: null,
      isSearching: false,
      isLoadingSubscription: false,
      typeNotifications: {
        room_message: {
          icon: "/static/icons/room.svg",
          url: "/messages"
        },
        room: {
          icon: "/static/icons/room.svg",
          url: "/messages"
        },
        chat: {
          icon: "/static/icons/messages.svg",
          url: "/messages"
        },
        chat_message: {
          icon: "/static/icons/messages.svg",
          url: "/messages"
        },
        follow_request: {
          icon: "/static/icons/follow.svg",
          url: "/user"
        },
        like: {
          icon: "/static/icons/jevi.svg",
          url: "/post"
        },
        like: {
          icon: "/static/icons/profile.svg",
          url: "/profile"
        },
        comment: {
          icon: "/static/icons/comment.svg",
          url: "/post"
        },
        post: {
          icon: "/static/icons/new_post.svg",
          url: "/post"
        },
        video: {
          icon: "/static/icons/video.svg",
          url: "/post"
        },
        station: {
          icon: "/static/icons/radio.svg",
          url: "/"
        },
        story: {
          icon: "/static/icons/video.svg",
          url: "/user"
        }
      },
      postPictureB64: "",
      storyPictureB64: "",
      postReportModal: false,
      reportReason: null,
      isReportLoading: false,
      reportingPost: {},
      poll: {
        questions: [
          {
            key: "new-question-" + 0,
            question: "",
            answers: [
              {
                answer: "",
                key: "new-answer-" + 0
              },
              {
                answer: "",
                key: "new-answer-" + 1
              }
            ]
          }
        ]
      },
      taggedUsers: [],
      id_country: "",
      sharingPost: {},
      shareId: null,
      shareModal: false,
      shareType: null,
      shareLoading: false,
      shareListLoading: false,
      chats: [],
      rooms: [],
      shareMsg: ""
    }
  },
  components: {
    VerticalNavMenu,
    NavbarMenu,
    NoAuthNavbarMenu,
    ProfilePicture,
    Comments,
    Searchbar,
    ProfileCard,
    StationCover,
    Cropper,
    Stories,
    Suggestions,
    VueEllipseProgress,
    Likes,
    PostText,
    PostType,
    LikedByModal,
    ActionBar
  },
  computed: {
    theme() {
      return this.$store.getters["getTheme"]
    },
    OAuthPreferences() {
      return this.$store.getters["auth/getOAuthPreferences"]
    },
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    },

    currentRadio() {
      return this.$store.getters["radio/getItem"]
    },
    showNotification() {
      return this.$store.getters["auth/getShowNotification"]
    },
    newNotification() {
      return this.$store.getters["auth/getNewNotification"]
    },
    searchModal() {
      return this.$store.getters["getSearchModal"]
    },
    postReportReasons() {
      return this.$store.getters["reportReason/getAllUnpaginated"].filter(
        (x) => x.type_report === 2
      )
    },
    countries() {
      return [{ value: null, es_name: "Todos", en_name: "All" }].concat(
        this.$store.getters["country/getAllUnpaginated"].map(
          ({ id, es_name, en_name }) => {
            return {
              es_name: es_name,
              en_name: en_name,
              value: id
            }
          }
        )
      )
    },
    resumablePostVideo() {
      return this.$store.getters["post/getResumableVideo"]
    },
    resumableStoryVideo() {
      return this.$store.getters["story/getResumableVideo"]
    }
  },
  created() {
    this.$store.dispatch("post/setResumableVideo", null)
    this.$store.dispatch("story/setResumableVideo", null)
    const vue = this

    setInterval(() => {
      if (
        (vue.resumablePostVideo || vue.resumableStoryVideo) &&
        !vue.stopChecking
      ) {
        //console.log("CHECK VIDEO UPLOAD")
        //TODO: Add type here
        const current = vue.resumablePostVideo || vue.resumableStoryVideo
        vue.checkUpload(
          current,
          true,
          vue.resumablePostVideo ? "video" : "story"
        )
      }
    }, 2000)

    if (this.loggedUser) {
      this.getUserLogged()
      this.getNotifications()
    }

    // Set global theme
    const mediaQueryList = window.matchMedia("(prefers-color-scheme: dark)")
    mediaQueryList.addEventListener("change", (event) => {
      this.$store.dispatch("setTheme", event.matches ? "dark" : "light")
    })
    this.$store.dispatch("setTheme", mediaQueryList.matches ? "dark" : "light")

    if (this.loggedUser) {
      this.$socket.emit("join_room", {
        id_user: this.loggedUser.id
      })
    }

    this.$store.dispatch("radio/setItem", null)
    this.$store.dispatch("auth/pushNewNotification", null)
    this.$store.dispatch("auth/showNewNotification", 0)
    this.$store.dispatch("setSearchModal", false)

    if (this.loggedUser) {
      this.getUserTop10()
      this.getRadioTop5()
      this.getHashtagsTop10()
      this.getReportReasons()
    }

    if (this.loggedUser) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude
          const longitude = position.coords.longitude
          this.$socket.emit("user_connected", {
            id_user: this.loggedUser.id,
            latitude,
            longitude
          })
          this.$socket.emit("join_topics", {
            id_user: this.loggedUser.id
          })
        },
        (err) => {
          console.log(err)
        }
      )

      if (this.loggedUser.id_role === 1 && this.countries.length === 1) {
        this.getCountries()
      }
    }
  },
  methods: {
    removePicture(position) {
      this["refresh"+position] = this["refresh"+position] + 1;
      this.poll["url_picture"+position] = null;
    },
    openLikesModal(postID) {
      // this.followListLoader = true
      this.postID = postID
      this.likesModal = true
    },
    closeLikesModal() {
      this.likesModal = false
      this.postID = 0
    },
    openProfileFromLikesModal(user) {
      this.postModal = false
      this.likesModal = false

      if (this.loggedUser && this.loggedUser.id === user.id) {
        this.$router.push("/profile")
      } else {
        this.$router.push(`/user/${user.username}`)
      }
    },
    subscribeChannel(element) {
      this.isLoadingSubscription = true
      this.$store
        .dispatch("global/create", {
          payload: {
            bearer_token:
              this.OAuthPreferences &&
                this.OAuthPreferences.accessToken &&
                this.OAuthPreferences.subscriptions
                ? this.OAuthPreferences.accessToken
                : null
          },
          route: "/post/subscribe_channel/" + element.id
        })
        .then((response) => {
          if (response.code != 500) {
            element.subscribed_to_channel = 1
          }

          notify().then(({ notification }) => {
            notification(
              "channel_subscribed",
              response.code,
              this.$snotify,
              this.$i18n.locale
            )
          })
          this.isLoadingSubscription = false
          this.$emit("subscribedChannel", response.data)
        })
        .catch((error) => {
          console.log(error)
          this.isLoadingSubscription = false
          notify().then(({ notification }) => {
            notification("error", 500, this.$snotify, this.$i18n.locale)
          })
        })
    },
    uploadVideo(data, type) {
      //console.log("UPLOAD VIDEO")

      const vue = this

      const oReq = new XMLHttpRequest()

      oReq.open("PUT", data.uri, true)

      oReq.onload = function (oEvent) {
        //Uploaded correctly
        //console.log({ responseGoogle: oEvent.target })

        if (oEvent.target.status == 200 || oEvent.target.status == 201) {
          //console.log("UPLOADED CORRECTLY")
          vue.updateElementStatus(data, type)
        } else {
          //console.log("AN ERROR OCCURRED WHILE UPLOADING! PROCEED TO CHECK UPLOAD...")
          vue.checkUpload(data, false, type)
        }
      }

      oReq.onerror = function (oEvent) {
        //console.log("AN ERROR OCCURRED WHILE UPLOADING! PROCEED TO CHECK UPLOAD...")
        vue.checkUpload(data, false, type)
      }

      oReq.send(this.galleryVideo)
    },
    checkUpload(data, check_only = false, type) {
      //console.log("CHECK UPLOAD")
      //console.log("Content-Range: bytes */"+this.galleryVideo.size)

      const vue = this

      // File size is in this.galleryVideo.size
      axios(data.uri, {
        method: "put",
        headers: {
          "Content-Range": "bytes */" + this.galleryVideo.size
          //'Content-Length': '0',
        }
      })
        .then((response) => {
          //console.log({ responseGoogle: response })

          if (response.status == 200 || response.status == 201) {
            //console.log("UPLOAD COMPLETED! PROCEED TO NULL RESUMABLE VIDEO...")
            vue.$store.dispatch(`${type}/setResumableVideo`, null)
          } else {
            //console.log("AN ERROR OCURRED WHILE CHECKING UPLOAD")

            if (response.headers.range) {
              //console.log("RANGE HEADER IS AVAILABLE")
              let range = response.headers.range

              let index = range.indexOf("-")
              let bytes_saved = parseInt(range.substring(index + 1))

              //console.log("BYTES SAVED: "+bytes_saved)

              let progress = parseInt(
                (bytes_saved / vue.galleryVideo.size) * 100
              )
              vue.$store.dispatch(
                `${type}/changeProgressResumableVideo`,
                progress
              )
              //console.log("PROGRESS: "+vue.resumableVideo.progress)

              if (!check_only) vue.resumeUpload(data, bytes_saved, type)
            } else {
              //console.log("RANGE HEADER NOT AVAILABLE")
              if (!check_only) vue.uploadVideo(data, type)
            }
          }
        })
        .catch((error) => {
          //console.log("AN ERROR OCCURRED WHILE CHECKING!")

          if (error.response.headers.range) {
            //console.log("RANGE HEADER IS AVAILABLE")
            let range = error.response.headers.range

            let index = range.indexOf("-")
            let bytes_saved = parseInt(range.substring(index + 1))

            //console.log("BYTES SAVED: "+bytes_saved)

            let progress = parseInt((bytes_saved / vue.galleryVideo.size) * 100)

            vue.$store.dispatch(
              `${type}/changeProgressResumableVideo`,
              progress
            )
            //console.log("PROGRESS: "+vue.resumableVideo.progress)

            if (!check_only) vue.resumeUpload(data, bytes_saved)
          } else {
            //console.log("RANGE HEADER NOT AVAILABLE")
            if (!check_only) vue.uploadVideo(data, type)
          }
        })
    },
    resumeUpload(data, bytes_saved, type) {
      //console.log("RESUME UPLOAD")
      const vue = this

      let missingSize = this.galleryVideo.size - bytes_saved
      let nextByte = bytes_saved
      let lastByte = this.galleryVideo.size - 1

      //console.log('Content-Range: bytes '+nextByte+'-'+lastByte+'/'+this.galleryVideo.size)
      //console.log('Content-Length: '+missingSize)

      fetch(data.uri, {
        method: "PUT",
        headers: new Headers({
          "Content-Range":
            "bytes " + nextByte + "-" + lastByte + "/" + this.galleryVideo.size,
          "Content-Length": missingSize
        })
      })
        .then((response) => {
          if (response.status == 200 || response.status == 201) {
            //Uploaded correctly
            //console.log("UPLOAD COMPLETED! PROCEED TO CHANGE STATUS...")
            vue.updateElementStatus(data, type)
          } else {
            //console.log("AN ERROR OCCURRED WHILE RESUMING UPLOAD! PROCEED TO CHECK UPLOAD...")
            vue.checkUpload(data, false, type)
          }
        })
        .catch((error) => {
          //console.log("AN ERROR OCCURRED WHILE RESUMING UPLOAD! PROCEES TO CHECK UPLOAD...")
          vue.checkUpload(data, false, type)
        })
    },
    cancelUpload(type) {
      if (!this.deleteConfirmation) {
        //console.log("DELETE CONFIRMATION SET TO TRUE")
        this.deleteConfirmation = true
        return
      }

      //console.log("PROCEED TO DELETE")
      const vue = this

      this.videoCancelLoading = true

      axios(vue.resumableVideo.uri, {
        method: "delete"
      })
        .then((response) => {
          if (response.status >= 400) {
            //console.log("UPLOAD CANCELLED CORRECTLY")
            this.videoCancelLoading = false
            this.$store.dispatch(`${type}/setResumableVideo`, null)

            notify().then(({ notification }) => {
              notification(
                `video-cancel`,
                response.status,
                this.$snotify,
                this.$i18n.locale
              )
            })

            this.deleteConfirmation = false
          } else {
            //console.log("AN ERROR OCURRED WHILE CANCELLING UPLOAD")
            this.videoCancelLoading = false
            notify().then(({ notification }) => {
              notification(
                "error",
                response.status,
                this.$snotify,
                this.$i18n.locale
              )
            })
          }
        })
        .catch((error) => {
          if (error.response.status >= 400) {
            //console.log("UPLOAD CANCELLED CORRECTLY")
            this.videoCancelLoading = false

            this.$store.dispatch(`${type}/setResumableVideo`, null)
            notify().then(({ notification }) => {
              notification(
                `video-cancel`,
                error.response.status,
                this.$snotify,
                this.$i18n.locale
              )
            })

            this.deleteConfirmation = false
          } else {
            //console.log("AN ERROR OCURRED WHILE CANCELLING UPLOAD")
            this.videoCancelLoading = false
            notify().then(({ notification }) => {
              notification(
                "error",
                error.response.status,
                this.$snotify,
                this.$i18n.locale
              )
            })
          }
        })
    },
    updateElementStatus(data, type) {
      //console.log("CHANGE POST STATUS")
      this.$store.dispatch(`${type}/changeProgressResumableVideo`, 100)
      this.stopChecking = true

      const words = data.text ? data.text.split(" ") : []
      const hashtags = []
      const usernames = []

      for (let word of words) {
        if (word.startsWith("#")) hashtags.push(word)
        if (word.startsWith("@")) usernames.push(word)
      }

      this.$store
        .dispatch("global/put", {
          route: `${type}/change_status/${data.id}/1`,
          payload: {
            uri: data.uri,
            hashtags: hashtags.length > 0 ? hashtags : null,
            ids_tagged_users: usernames.length > 0 ? usernames : null
          },
          useAuthorization: true
        })
        .then((response) => {
          this.deleteConfirmation = false
          this.$store.dispatch(`${type}/setResumableVideo`, null)
          this.stopChecking = false

          notify().then(({ notification }) => {
            notification(
              type,
              response.data.code,
              this.$snotify,
              this.$i18n.locale
            )
          })

          if (response.data.code === 200) {
            if (type === "post") this.newPostHousekeeping(true, true)
            else this.newStoryHouseKeeping(true)
          }
        })
        .catch((errorx) => {
          //this.newPostLoading = false
          console.log({ putError: errorx })
        })
    },
    returnCreateModal() {
      this.postType = 3
      this.videoType = null
    },
    sharePost() {
      if (this.shareType === 3) return this.sendRepost()

      if (!this.shareMsg || this.shareMsg === "") {
        return this.$snotify.error(
          this.$i18n.locale === "en"
            ? "You have to type a message"
            : "Debes escribir un mensaje",
          "Error",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom"
          }
        )
      }

      this.shareLoading = true
      let form = {
        id_user: this.loggedUser.id,
        id_post: this.sharingPost.id,
        type_file: 0,
        message: this.shareMsg
      }

      let route = ""

      if (this.shareType === 1) {
        form.id_chat = this.shareId
        route = "/chat_message/save"
      }
      if (this.shareType === 2) {
        form.id_room = this.shareId
        route = "/room_message/save"
      }

      if (this.shareMsg || this.shareMsg !== "") form.message = this.shareMsg
      this.$store
        .dispatch("global/create", {
          payload: form,
          route
        })
        .then((response) => {
          this.shareLoading = false
          notify().then(({ notification }) => {
            notification(
              "share-post",
              response.code,
              this.$snotify,
              this.$i18n.locale
            )
          })
          this.closeModal("shareModal")
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
          this.shareLoading = false
        })
    },
    triggerSharePost(data) {
      this.sharingPost = data
      this.shareModal = true
    },
    getCountries() {
      this.$store
        .dispatch("global/getItems", {
          route: "/country/all",
          module: "country",
          isMaster: true,
          noPaginate: true,
          params: {
            orderKey: this.$i18n.locale + "_name",
            orderValue: "asc",
            active: 1
          }
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    openStoryInstead() {
      this.closeModal("newPostModal")
      this.storyCreationModal = true
    },
    openStory(data) {
      this.$store.dispatch("story/setItem", data)
      this.storyModal = true
    },
    compressedImage(element) {
      return element.imgkit_url_picture
        ? element.imgkit_url_picture
        : element.url_picture
    },
    addAnswerToQuestion(index1, index2, answer) {
      if (index2 === 3) return
      if (!answer || answer.length == 0) {
        if (
          index2 == this.poll.questions[index1].answers.length - 2 &&
          index2 !== 0
        ) {
          this.poll.questions[index1].answers.splice(index2 + 1, 1)
        }
      } else {
        if (index2 == this.poll.questions[index1].answers.length - 1) {
          this.poll.questions[index1].answers.push({
            answer: null,
            key:
              "new-answer-" +
              this.poll.questions.length +
              this.poll.questions[index1].answers.length
          })
        }
      }
    },
    onRepostLoad(loadingRef, pictureRef, id) {
      if (this.$refs[loadingRef + id] && this.$refs[loadingRef + id].classList)
        this.$refs[loadingRef + id].classList.add("hidden")

      if (this.$refs[pictureRef + id] && this.$refs[pictureRef + id].classList)
        this.$refs[pictureRef + id].classList.remove("hidden")
    },
    onImageLoad(loadingRef, pictureRef, id) {
      if (this.$refs[loadingRef + id] && this.$refs[loadingRef + id].classList)
        this.$refs[loadingRef + id].classList.add("hidden")

      if (
        this.$refs[loadingRef + id][0] &&
        this.$refs[loadingRef + id][0].classList
      )
        this.$refs[loadingRef + id][0].classList.add("hidden")

      if (this.$refs[pictureRef + id] && this.$refs[pictureRef + id].classList)
        this.$refs[pictureRef + id].classList.remove("hidden")

      if (
        this.$refs[pictureRef + id][0] &&
        this.$refs[pictureRef + id][0].classList
      )
        this.$refs[pictureRef + id][0].classList.remove("hidden")
    },
    reportPost() {
      if (!this.reportReason) {
        this.$snotify.error(
          this.$i18n.locale === "en"
            ? "Select a report reason"
            : "Selecciona un motivo para el reporte",
          this.$i18n.locale === "en" ? "Error" : "Error",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom"
          }
        )
      }
      this.isReportLoading = true
      this.$store
        .dispatch("global/create", {
          payload: {
            id_reporting_reason: this.reportReason,
            id_user_reporting: this.loggedUser.id,
            id_post: this.reportingPost.id
          },
          route: "/post_reported/save",
          module: null
        })
        .then((response) => {
          this.postReportModal = false
          this.isReportLoading = false
          if (response.code === 409) {
            notify().then(({ notification }) => {
              notification(
                "report_post",
                response.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          } else {
            notify().then(({ notification }) => {
              notification(
                "report",
                response.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          }
        })
        .catch((error) => {
          this.isReportLoading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    openPostReport(element) {
      this.reportingPost = element
      this.postReportModal = true
    },
    getReportReasons() {
      this.$store
        .dispatch("global/getItems", {
          route: "/reporting_reason/all",
          module: "reportReason",
          noPaginate: true,
          isMaster: true
        })
        .then((response) => { })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    updateTop10User(data) {
      let index = this.user_top10.findIndex((x) => x.id === data.id)
      if (index !== -1) this.user_top10[index] = data
    },
    cropperChange(e, element) {
      e.canvas.toBlob((blob) => {
        const file = new File([blob], "image.png")
        this[element] = file
      })
    },
    changeAspectRatio(type) {
      if (type === "squared") {
        this.aspectRatio = 1
      } else {
        this.aspectRatio = 9 / 16
      }
      this.$refs.cropperStory.reset()
    },
    handleProcessedTextClick(e) {
      if (e.target.matches(".post-hashtag")) {
        this.$store
          .dispatch("global/getItems", {
            route: "/hashtag/all",
            module: null,
            noPaginate: true,
            isMaster: true,
            params: {
              //query: e.target.innerHTML.slice(1),
              name: e.target.innerHTML,
              limit: 1,
              active: 1
            }
          })
          .then((response) => {
            if (response.data[0]) {
              this.postModal = false
              this.$router.push("/post_by_hashtag/" + response.data[0].name.slice(1))
            } else {
              notify().then(({ notification }) => {
                notification("error", 404, this.$snotify, this.$i18n.locale)
              })
            }
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
      if (e.target.matches(".post-username")) {
        this.$store
          .dispatch("global/getItems", {
            route: "/user/all",
            module: null,
            noPaginate: true,
            params: {
              username: e.target.innerHTML.slice(1),
              limit: 1,
              active: 1
            }
          })
          .then((response) => {
            if (response.data[0]) {
              this.postModal = false
              this.openProfile(response.data[0])
            } else {
              notify().then(({ notification }) => {
                notification("error", 404, this.$snotify, this.$i18n.locale)
              })
            }
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
    },

    playRadio(radio) {
      this.$store.dispatch("radio/setItem", radio)
      this.activeRadio = true
    },
    openPostsByHashtag(hashtag) {
      this.closeModal("searchModal")
      this.$router.push("/post_by_hashtag/" + hashtag.name.slice(1))
    },
    searchbarLoading(loading) {
      if (loading) this.isSearching = loading
    },
    getUserTop10() {
      this.$store
        .dispatch("global/getItems", {
          route: "/user/top_10/" + this.loggedUser.id,
          module: null,
          noPaginate: true
        })
        .then((response) => {
          let user_data = response.data.data.map((x) => x.user)
          for (let i in user_data) user_data[i].followed_by_you_status = 0
          this.user_top10 = user_data
        })
        .catch((error) => {
          console.log("error", error)
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    getRadioTop5() {
      this.$store
        .dispatch("global/getItems", {
          route: "/station/all",
          module: null,
          noPaginate: true,
          params: {
            order_key: "ordering",
            order_value: "asc",
            limit: 5,
            active: 1
          }
        })
        .then((response) => {
          this.radio_top5 = response.data
          this.$store.dispatch("radio/setSuggestedElements", response.data)
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    getHashtagsTop10() {
      this.$store
        .dispatch("global/getItems", {
          route: "/hashtag/all",
          module: null,
          noPaginate: true,
          isMaster: true,
          params: {
            order_key: "total_posts",
            order_value: "desc",
            limit: 10,
            active: 1
          }
        })
        .then((response) => {
          this.hashtags_top10 = response.data
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    loadSearch() {
      this.searchTriggered = true
      if (this.searchQuery === "") {
        this.searchTriggered = false
        this.searchResults = []
        this.isSearching = false
        return
      }
      if (this.searchType === "user") {
        this.searchUsers()
      }
      if (this.searchType === "radio") {
        this.searchRadios()
      }
      if (this.searchType === "hashtag") {
        this.searchHashtagPosts()
      }
    },
    searchUsers() {
      this.$store
        .dispatch("global/getItems", {
          route: "/user/all",
          module: null,
          noPaginate: true,
          params: {
            query: this.searchQuery,
            limit: 5,
            active: 1
          }
        })
        .then((response) => {
          this.searchResults = response.data
          this.isSearching = false
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    searchRadios() {
      this.$store
        .dispatch("global/getItems", {
          route: "/station/all",
          module: null,
          noPaginate: true,
          params: {
            query: this.searchQuery,
            limit: 5,
            active: 1
          }
        })
        .then((response) => {
          this.searchResults = response.data
          this.isSearching = false
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    searchHashtagPosts() {
      this.$store
        .dispatch("global/getItems", {
          route: "/hashtag/all",
          module: null,
          isMaster: true,
          noPaginate: true,
          params: {
            query: this.searchQuery,
            limit: 5,
            active: 1
          }
        })
        .then((response) => {
          this.searchResults = response.data
          this.isSearching = false
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    changeQuery(value) {
      this.searchQuery = value
      this.loadSearch()
    },
    updateSearchQuery() {
      this.$store.dispatch("setSearchQuery", this.searchQuery)
    },
    stationChat() {
      this.$router.push("/messages?t=sr")
    },
    handleVideoPlay(postId, htmlElement) {
      if (!htmlElement.classList.contains("viewed")) {
        htmlElement.classList.add("viewed")
        this.$store
          .dispatch("global/update", {
            payload: {
              id: postId,
              new_view: 1
            },
            route: "/post/update",
            module: null
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
    },
    getNotifications() {
      const t = new Date()
      const z = t.getTimezoneOffset() * 60 * 1000
      const local_datetime = new Date(t - z)
        .toISOString()
        .slice(0, 19)
        .replace("T", " ")

      this.$store
        .dispatch("global/getItemsV2", {
          route: "/notification/grouped_by_date",
          module: null,
          noPaginate: true,
          params: {
            active: 1,
            id_user: this.loggedUser.id,
            order_key: "updated_at",
            order_value: "desc",
            datetime: local_datetime
          }
        })
        .then((response) => {
          this.$store.dispatch(
            "auth/setNotifications",
            response.data.data ? response.data.data : response.data
          )
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    triggerAudioError() {
      this.$snotify.error(
        this.$i18n.locale === "en"
          ? "An error occurred while loading the radio"
          : "Ocurrió un error al cargar la radio",
        "Error",
        {
          timeout: 5000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          titleMaxLength: 40,
          bodyMaxLength: 100,
          position: "rightBottom"
        }
      )
    },
    resetRadio() {
      if (this.audioStatus === "playing") {
        this.audioStatus = "paused"
        this.$refs["audio-element"].pause()
      }

      this.activeRadio = false
      this.$socket.emit("leave_room", {
        id_user: this.loggedUser.id,
        id_station_room: this.currentRadio.id
      })
      this.$store.dispatch("radio/setItem", null)
      this.$store.dispatch("stationRoom/setElement", null)
    },
    toggleAudio(action) {
      if (action === "play") {
        this.$refs["audio-element"].play()
        this.audioStatus = "playing"
      } else if (action === "pause") {
        this.$refs["audio-element"].pause()
        this.audioStatus = "paused"
      }
    },
    openProfile(user) {
      this.postModal = false
      this.storyModal = false
      this.$store.dispatch("setSearchModal", false)
      if (this.loggedUser && this.loggedUser.id === user.id) {
        this.$router.push("/profile")
      } else {
        this.$router.push(`/user/${user.username}`)
      }
    },
    likePost(post) {
      this.$store
        .dispatch("global/create", {
          payload: {
            id_user: this.loggedUser.id,
            type_child: "post",
            id_child: post.id,
            bearer_token:
              this.OAuthPreferences &&
                this.OAuthPreferences.accessToken &&
                this.OAuthPreferences.likes_comments
                ? this.OAuthPreferences.accessToken
                : null
          },
          route: "/like/save",
          module: null
        })
        .catch((error) => {
          console.log("post like error:", error)
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    calculateHighNumber(number) {
      if (isNaN(number)) return 0
      if (Number(number) == 1000000) return "1M"
      if (Number(number) > 1000000) return (number / 1000000).toFixed(2) + "M"
      if (Number(number) == 1000) return "1K"
      if (Number(number) > 1000) return (number / 1000).toFixed(2) + "K"

      return number
    },
    getYoutubeEmbedLink(link) {
      if (!link) return
      let base_url = "https://www.youtube.com/embed/"
      if (link.includes("youtube")) {
        return (
          base_url +
          link.split("?v=")[1] +
          `?feature=oembed&enablejsapi=1&Origin=${window.location.host}`
        )
      } else if (link.includes("youtu.be")) {
        return (
          base_url +
          link.split("/")[3] +
          `?feature=oembed&enablejsapi=1&Origin=${window.location.host}`
        )
      } else {
        return ""
      }
    },
    processPostVideoChange() {
      if (this.postVideo.length === 0) {
        this.embedUrl = null
        this.postVideoThumbnail = ""
      }
      if (
        !/^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/.test(
          this.postVideo
        )
      )
        this.embedUrl = null
      this.postVideoThumbnail = ""
    },
    validateVideo() {
      this.$validator.validate("postVideo").then((isValid) => {
        if (isValid) {
          this.embedUrl = this.getYoutubeEmbedLink(this.postVideo)
          fetch(`https://youtube.com/oembed?url=${this.postVideo}&format=json`)
            .then((response) => response.json())
            .then((data) => {
              this.postVideoThumbnail = data.thumbnail_url
            })
            .catch((error) => {
              console.log("Video error:", error)
              this.$snotify.error(
                this.$i18n.locale === "en"
                  ? "An error occurred while processing your youtube video URL"
                  : "Ocurrió un error al procesar el URL de su video de Youtube",
                "Error",
                {
                  timeout: 5000,
                  showProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  titleMaxLength: 40,
                  bodyMaxLength: 100,
                  position: "rightBottom"
                }
              )
            })
        }
      })
    },
    closeModal(modalName) {
      if (modalName === "newPostModal") {
        this.postDescription = ""
        this.postType = 3
        this.postPicture = null
        this.postPictureB64 = ""
      } else if (modalName === "postModal") {
        this.commentText = ""
      } else if (modalName === "searchModal") {
        this.$store.dispatch("setSearchModal", false)
        this.searchQuery = ""
        this.$store.dispatch("setSearchQuery", "")
        this.searchResults = []
        this.searchTriggered = false
      } else if (modalName === "postReportModal") {
        this.postReportModal = false
        this.reportReason = null
        this.reportingPost = {}
      } else if (modalName === "storyModal") {
      } else if (modalName === "storyCreationModal") {
        this.storyText = ""
        this.storyPicture = null
        this.storyPictureB64 = ""
      } else if (modalName === "shareModal") {
        this.shareId = null
        this.shareModal = false
        this.shareMsg = ""
        this.sharingPost = {}
        this.shareType = null
        if (this.postModal) this.closeModal("postModal")
      }
      if (this[modalName]) this[modalName] = false
    },
    collapseExpand() {
      this.menuExpanded = !this.menuExpanded
      if (this.menuExpanded && window.matchMedia("(max-width: 992px)").matches)
        disableScrolling()
      else enableScrolling()
    },
    sendPost() {
      if (this.postType === 4)
        this.postDescription = this.poll.questions[0].question
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          const words = this.postDescription.split(" ")
          const hashtags = []
          const usernames = []
          for (let word of words) {
            if (word.startsWith("#")) hashtags.push(word)
            if (word.startsWith("@")) usernames.push(word)
          }

          this.newPostLoading = true
          let form = {
            id_user: this.loggedUser.id,
            text: this.postDescription,
            type: this.postType
          }

          if (this.postType === 1) {
            form.url_picture = this.postPicture
          }

          if (this.postType === 2) {
            if (this.videoType === "gallery") {
              form.aspect_ratio = this.videoAspectRatio
              form.video_format = this.$getExtension(this.galleryVideo)
              form.type = 5
            } else {
              form.url_video = this.postVideo
              form.url_picture = this.postVideoThumbnail
            }
          }

          if (this.postType === 4) {
            if (this.poll.url_picture1)
              form.url_picture1 = this.poll.url_picture1
            if (this.poll.url_picture2)
              form.url_picture2 = this.poll.url_picture2
            if (this.poll.url_picture3)
              form.url_picture3 = this.poll.url_picture3
            if (this.poll.url_picture4)
              form.url_picture4 = this.poll.url_picture4

            form.questions = JSON.stringify(this.poll.questions)
          }

          if (this.id_country && this.id_country !== "")
            form.id_country = this.id_country

          if (hashtags.length > 0) form.hashtags = hashtags
          if (usernames.length > 0) form.ids_tagged_users = usernames

          this.$store
            .dispatch("global/create", {
              payload: form,
              route: this.postType === 4 ? "/post/poll/save" : "/post/save",
              module: null
            })
            .then((response) => {
              if (Number(response.data.type) === 5 && response.data.uri) {
                console.log("POST CREATED! PROCEED TO UPLOAD...")

                this.newPostLoading = false
                this.newPostHousekeeping(false, true)
                this.$store.dispatch("post/setResumableVideo", {
                  ...response.data,
                  thumbnail: this.galleryVideo,
                  progress: 0
                })
                this.uploadVideo(response.data, "post")
                return
              }

              notify().then(({ notification }) => {
                notification(
                  "post",
                  response.code,
                  this.$snotify,
                  this.$i18n.locale
                )
              })

              if ([200, 201].includes(response.code)) {
                this.newPostHousekeeping()
              }

              this.newPostLoading = false
            })
            .catch((error) => {
              this.newPostLoading = false
              console.log("post creation error:", error)
              notify().then(({ notification }) => {
                notification(
                  "error",
                  error.code,
                  this.$snotify,
                  this.$i18n.locale
                )
              })
            })
        }
      })
    },
    sendRepost() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          const words = this.postDescription.split(" ")
          const hashtags = []
          const usernames = []
          for (let word of words) {
            if (word.startsWith("#")) hashtags.push(word)
            if (word.startsWith("@")) usernames.push(word)
          }

          this.shareLoading = true
          let form = {
            id_user: this.loggedUser.id,
            id_user_origin: this.sharingPost.id_user,
            id_post_origin: this.sharingPost.id,
            text: this.postDescription,
            type: this.sharingPost.type,
            url_video: this.sharingPost.url_video,
            imgkit_url_video: this.sharingPost.imgkit_url_video,
            url_picture: this.sharingPost.url_picture,
            repost: 1
          }

          if (hashtags.length > 0) form.hashtags = hashtags
          if (usernames.length > 0) form.ids_tagged_users = usernames

          this.$store
            .dispatch("global/create", {
              payload: form,
              route: "/post/save",
              module: null
            })
            .then((response) => {
              notify().then(({ notification }) => {
                notification(
                  "post",
                  response.code,
                  this.$snotify,
                  this.$i18n.locale
                )
              })
              if ([200, 201].includes(response.code)) {
                this.$store.dispatch("post/setLoadNewPosts", true)
                this.postDescription = ""
                if (this.$route.path !== "/home") {
                  this.$router.push("/home")
                }
                this.closeModal("shareModal")
              }
              this.shareLoading = false
            })
            .catch((error) => {
              this.shareLoading = false
              console.log("repost error:", error)
              notify().then(({ notification }) => {
                notification(
                  "error",
                  error.code,
                  this.$snotify,
                  this.$i18n.locale
                )
              })
            })
        }
      })
    },
    sendStory() {
      this.newStoryLoading = true

      let form = {
        id_user: this.loggedUser.id,
        text: this.storyText,
        story: this.storyPicture,
        type: 1
      }

      if (this.galleryVideo) {
        form.aspect_ratio = this.videoAspectRatio
        form.video_format = this.$getExtension(this.galleryVideo)
        form.type = 2
      }

      this.$store
        .dispatch("global/create", {
          payload: form,
          route: "/story/save",
          module: null
        })
        .then((response) => {
          if ([200, 201].includes(response.code)) {
            if (Number(response.data.type) === 2 && response.data.uri) {
              console.log("STORY CREATED! PROCEED TO UPLOAD...")

              this.newStoryHouseKeeping(false)
              this.$store.dispatch("story/setResumableVideo", {
                ...response.data,
                thumbnail: this.galleryVideo,
                progress: 0
              })
              this.uploadVideo(response.data, "story")
              return
            }
            this.newStoryHouseKeeping()

            notify().then(({ notification }) => {
              notification(
                "story",
                response.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          }
        })
        .catch((error) => {
          this.newStoryLoading = false
          console.log("story creation error:", error)
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    startLoading() {
      this.isLoading = true
    },
    finishLoading() {
      this.isLoading = false
    },
    getUserLogged() {
      this.$store.dispatch("auth/getUserLogged").catch((error) => {
        notify().then(({ notification }) => {
          notification("error", error.code, this.$snotify, this.$i18n.locale)
        })
      })
    },
    sendConfirmation() {
      this.$store
        .dispatch("auth/sendConfirmation", { email: this.loggedUser.email })
        .then((response) => {
          notify().then(({ notification }) => {
            notification(
              "resend",
              response.code,
              this.$snotify,
              this.$i18n.locale
            )
          })
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    newPostHousekeeping(refreshPosts = true, videoUpload = false) {
      if (refreshPosts) this.$store.dispatch("post/setLoadNewPosts", true)
      this.postDescription = ""
      this.postPicture = null
      this.postVideo = ""
      this.postVideoThumbnail = ""
      this.embedUrl = null
      this.postType = 3
      this.poll = {
        questions: [
          {
            key: "new-question-" + 0,
            question: "",
            answers: [
              {
                answer: "",
                key: "new-answer-" + 0
              },
              {
                answer: "",
                key: "new-answer-" + 1
              }
            ]
          }
        ]
      }
      if (!videoUpload && this.$route.path !== "/home") {
        this.$router.push("/home")
      }
      this.closeModal("newPostModal")
    },
    newStoryHouseKeeping(refreshStories = true) {
      this.newStoryLoading = false
      this.closeModal("storyCreationModal")
      if (refreshStories && this.$route.path === "/home") {
        this.$store.dispatch("auth/getUserLogged")
        this.$store.dispatch("story/setLoadNew", true)
      }
    }
  },
  watch: {
    shareType: {
      handler() {
        this.shareId = null
        if (this.shareType === 1 && this.chats.length === 0) {
          this.shareListLoading = true
          this.$store
            .dispatch("global/getItems", {
              route: "/chat/all",
              noPaginate: true,
              params: {
                id_user: this.loggedUser.id,
                chat_available: 1,
                order_key: "updated_at",
                order_value: "desc",
                active: 1
              }
            })
            .then((response) => {
              this.chats = response.data.map((chat) => ({
                user:
                  chat.id_user_a === this.loggedUser.id
                    ? chat.user_b
                    : chat.user_a,
                id_chat: chat.id
              }))
              this.shareListLoading = false
            })
            .catch((error) => {
              this.shareListLoading = false
              console.log(error)
              notify().then(({ notification }) => {
                notification(
                  "error",
                  error.code,
                  this.$snotify,
                  this.$i18n.locale
                )
              })
            })
        }
        if (this.shareType === 2 && this.rooms.length === 0) {
          this.shareListLoading = true
          this.$store
            .dispatch("global/getItems", {
              route: "/room_user/all",
              noPaginate: true,
              params: {
                id_user: this.loggedUser.id,
                chat_available: 1,
                order_key: "updated_at",
                order_value: "desc",
                active: 1
              }
            })
            .then((response) => {
              this.rooms = response.data.map((x) => x.room)
              this.shareListLoading = false
            })
            .catch((error) => {
              this.shareListLoading = false
              notify().then(({ notification }) => {
                notification(
                  "error",
                  error.code,
                  this.$snotify,
                  this.$i18n.locale
                )
              })
            })
        }
      }
    },
    currentRadio: {
      handler() {
        if (this.activeRadio) {
          this.$nextTick(() => {
            this.toggleAudio("play")
            this.$socket.emit("join_room", {
              id_user: this.loggedUser.id,
              id_station_room: this.currentRadio.id
            })
          })
        } else {
          this.audioStatus = "paused"
        }
      },
      deep: true
    },
    radioVolume: {
      handler() {
        this.$refs["audio-element"].volume = parseFloat(this.radioVolume / 100)
      }
    },
    postModal: {
      handler() {
        if (this.postModal) {
          this.$nextTick(function () {
            if (this.currentPost && this.currentPost.type === 2) {
              const intervalId = setInterval(() => {
                let currentPostId = this.currentPost.id
                if (
                  document.getElementById(`main-video-player-${currentPostId}`)
                ) {
                  window.handleVideoPlay = this.handleVideoPlay

                  function onPlayerStateChange(event) {
                    let currentPostId = event.target.h.id.split("-player-")[1]
                    let htmlId = event.target.h.id
                    if (event.data == YT.PlayerState.PLAYING) {
                      window.handleVideoPlay(
                        parseInt(currentPostId),
                        document.getElementById(htmlId).classList.add("viewed")
                      )
                    }
                  }

                  const player = new YT.Player(
                    `main-video-player-${currentPostId}`,
                    {
                      playerVars: {
                        origin: window.location.host,
                        widget_referrer: window.location.host
                      },
                      events: {
                        onStateChange: onPlayerStateChange
                      }
                    }
                  )
                  clearInterval(intervalId)
                }
              }, 100)
            }
          })
        }
      }
    },
    showNotification() {
      if (this.showNotification === 1) {
        if (this.newNotification && this.newNotification.id >= 0) {
          let notif = { ...this.newNotification }
          let notif_config = this.typeNotifications[notif.type_child]
          let selected_chat = false
          let chats_type_childs = ["chat_message", "room_message"]
          let chat

          if (
            this.$route.path === "/messages" &&
            chats_type_childs.includes(notif.type_child)
          ) {
            if (notif.type_child === "chat_message") {
              chat = this.$store.getters["chat/getElement"]
              if (chat && chat.id === notif.id_child) selected_chat = true
            } else if (notif.type_child === "room_message") {
              chat = this.$store.getters["room/getElement"]
              if (chat && chat.id === notif.id_child) selected_chat = true
            }
          }
          if (this.$snotify.notifications.length < 3 && !selected_chat) {
            const toast = this.$snotify.info(
              notif.title + ": " + notif.description,
              " ",
              {
                timeout: 5000,
                showProgressBar: false,
                pauseOnHover: true,
                titleMaxLength: 90,
                bodyMaxLength: 100,
                position: "rightBottom",
                icon: `${notif_config.icon}`
              }
            )

            if (notif.type_child === "post" || notif.type_child === "comment") {
              notif_config.url = `/post/${notif.post.id}`
            }

            if (
              notif.type_child === "follow_request" ||
              notif.type_child === "story"
            )
              notif_config.url = `/user/${notif.user_notification.username}`

            toast.on("click", (toast) => {
              this.$snotify.remove(toast.id)
              this.$snotify.notifications = this.$snotify.notifications.filter(
                (x) => x.id !== toast.id
              )
              this.$router.push(notif_config.url)
            })
          }

          this.$store.dispatch("auth/pushNewNotification", null)
          this.$store.dispatch("auth/showNewNotification", 0)
        }
      }
    },
    searchType() {
      if (this.searchQuery !== "") this.loadSearch()
    },
    loggedUser() {
      if (this.loggedUser.active === 0)
        this.$store.dispatch("auth/disabledUser")
    }
  }
}
</script>
