<template>
  <div class="flex relative">
    <t-input
      :autofocus="autofocus"
      v-model="query"
      @keydown="(event) => handleKeyDown(event)"
      @keyup="debouncedInput"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      :placeholder="placeholder"
      class="bg-white dark:bg-gray-800 dark:text-white rounded-3xl border-0 active:border-gray-500 focus:border-gray-500 placeholder-gray-400 shadow-lg pr-0 sm:pr-10"
    >
    </t-input>
    <img
      v-if="!isLoading"
      src="/static/icons/search.svg"
      class="absolute top-1/3 right-4 dark:filter invert select-none"
      alt="Search icon"
      width="18px"
      height="18px"
      style="
        filter: invert(57%) sepia(47%) saturate(6150%) hue-rotate(217deg)
          brightness(94%) contrast(97%);
      "
    />
    <t-loader
      v-if="isLoading"
      extraClass="absolute top-1/3 right-4 border-orange-primary"
    />
  </div>
</template>
<script>
import { debounce } from "lodash"
export default {
  props: {
    placeholder: {
      type: String,
      default: ""
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      query: "",
      isLoading: false,
      keyExceptions: [
        "Control",
        "Tab",
        "Shift",
        "Alt",
        "CapsLock",
        "Escape",
        "PageUp",
        "PageDown",
        "End",
        "Home",
        "Arrow",
        "Insert"
      ]
    }
  },
  methods: {
    handleKeyDown(event) {
      if (this.keyExceptions.includes(event.key)) return
      this.isLoading = true
      if (this.query === "") {
        this.$emit("loading", false)
      } else {
        this.$emit("loading", true)
      }
    },
    emitQueryChange() {
      this.$emit("changeQuery", this.query)
      this.isLoading = false
      this.$emit("loading", false)
    },
    debouncedInput() {}
  },
  created() {
    this.debouncedInput = debounce(this.emitQueryChange, 200)
  },
  watch: {
    query() {
      if (this.query === "") {
        this.loading = false
        this.$emit("loading", false)
      }
    }
  }
}
</script>
